import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";

import {
    Box, Button, CircularProgress, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TableSortLabel, Typography, Modal
} from '@mui/material';
import { Styles } from './Styles';
import "./Styles.css";
import { fontStyles } from '../../../styles/fontStyles';
import { buttonStyles } from '../../../styles/buttonStyles';
import { tableStyles } from '../../../styles/tableStyles';
import { IconSvg } from '../../../utils/globalIcons';

import getAgentAPiTokenData from '../../../utils/ApiFunctions/AgentAPiTokenData';
import { setAgentApiTokenState } from "../../../Redux/slice/dataSlice";
import { setAgentApiTokenAdded } from "../../../Redux/slice/newDataSlice";
import LoadBar from '../../../utils/LoadBar';
import AgentApiToken from '../../../Api/Services/AppflyteBackend/AgentApiToken';
import { tostAlert } from '../../../utils/AlertToast';
import { mainStyles } from '../../../styles/styles';

function APiKeys() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [allTokens, setAllTokens] = useState([]);
    const [tokenDatarowsPerPage, setTokenDataRowsPerPage] = useState('5');
    const [tokenDataPage, setTokenDataPage] = useState(1);
    const [totalTokenDataPages, setTotalTokenDataPages] = useState(1);

    // Handling delete
    const deleteDetails = { item_id: '', modalstatus: false };
    const [deleteData, setDeleteData] = useState(deleteDetails);

    const all_agent_api_tokens = useSelector(state => state.all_data.agent_api_tokens)
    const agent_api_token_added = useSelector(state => state.data_added.agent_api_token_added)

    useEffect(() => {
        getAllTokensDetails()
    }, [agent_api_token_added])

    const getAllTokensDetails = async () => {
        setLoading(true)
        try {
            if (all_agent_api_tokens?.length > 0 && agent_api_token_added === false) {
                setAllTokens(all_agent_api_tokens)
                return
            }
            const response = await getAgentAPiTokenData();
            dispatch(setAgentApiTokenState(response))
            dispatch(setAgentApiTokenAdded(false))
            setAllTokens(response)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setTotalTokenDataPages(Math.ceil(allTokens?.length / parseInt(tokenDatarowsPerPage)));
    }, [allTokens, tokenDatarowsPerPage]);

    let PaginatedTokenData = [];
    const handleTokenDataPage = (event, newPage) => { setTokenDataPage(newPage) }

    const handleTokenDataRowsPerPageChange = event => {
        setTokenDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(allTokens?.length / parseInt(newRowsPerPage));
        setTokenDataRowsPerPage(newRowsPerPage);
        setTokenDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalTokenDataPages(newTotalPages);
    };
    if (allTokens?.length > 0) {
        const startIndex = (tokenDataPage - 1) * parseInt(tokenDatarowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(tokenDatarowsPerPage), allTokens?.length);
        PaginatedTokenData = allTokens?.slice(startIndex, endIndex);
    }

    const handleDeleteAlertModalOpen = (item_id) => {
        setDeleteData({ ...deleteData, modalstatus: true, item_id: item_id });
    }

    const handleDeleteAlertModalClose = () => {
        setDeleteData(deleteDetails);
    }

    const handleTokenDelete = async () => {
        setLoading(true)
        try {
            setDeleteData({ ...deleteData, modalstatus: false })
            const response = await AgentApiToken.deleteToken(deleteData.item_id)
            if (response.status === 200) {
                tostAlert('Token Deleted Successfully', 'success');
                dispatch(setAgentApiTokenAdded(true))
                return
            }
            tostAlert('Token Deletion Failed', 'error');
        } catch (error) {
            console.log(error)
        } finally {
            setDeleteData(deleteDetails);
            setLoading(false)
        }
    }

    const handleCreateNewKey = () => {
        navigate('/settings/api-keys/create-api-keys')
    }

    return (
        <Box sx={Styles.mainContainer}>
            {loading && <LoadBar />}

            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={fontStyles.largeText}>API Keys</Typography>

                <Box display={'flex'} alignItems={'center'}>
                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '180px' }}
                        onClick={() => handleCreateNewKey()}
                    >
                        <Typography sx={fontStyles.btnText}>CREATE NEW KEY</Typography>
                    </Button>
                </Box>

            </Box>

            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                This API key is tied to your user and can make requests against this project.
            </Typography>

            <Box marginTop={'30px'} paddingBottom={'20px'}>
                <Paper>
                    < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                        <Table
                            stickyHeader sx={{ height: PaginatedTokenData.length <= 0 ? '400px' : `${allTokens.length}px` }}
                        >
                            <TableHead sx={tableStyles.thead}>
                                <TableRow>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '18%' }}>
                                        <Typography sx={tableStyles.theadText}>Name</Typography>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '18%' }}>
                                        <Typography sx={tableStyles.theadText}>Created On</Typography>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '18%' }}>
                                        <Typography sx={tableStyles.theadText}>Last Used</Typography>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '18%' }}>
                                        <Typography sx={tableStyles.theadText}>Owned By</Typography>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '18%' }}>
                                        <Typography sx={tableStyles.theadText}>Permissions</Typography>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '10%' }}>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >

                                {allTokens?.length === 0 && loading ?
                                    (<TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    (allTokens?.length > 0 && PaginatedTokenData?.map((item, index) => (
                                        < TableRow key={index} sx={tableStyles.trow}>
                                            <TableCell sx={tableStyles.tdcell}>{item?.payload?.name}</TableCell>
                                            <TableCell sx={tableStyles.tdcell}>{item?.payload?.created_on}</TableCell>
                                            <TableCell sx={tableStyles.tdcell}>{item?.payload?.last_used}</TableCell>
                                            <TableCell sx={tableStyles.tdcell}>{item?.payload?.owned_by}</TableCell>
                                            <TableCell sx={tableStyles.tdcell}>{item?.payload?.permissions}</TableCell>
                                            <TableCell sx={tableStyles.tdcell}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <span className='edit_icon'> <ReactSVG src={IconSvg.editIcon} className='edit_icon' /></span>
                                                    <span
                                                        style={{ marginLeft: '20px' }}
                                                        className='delete_icon'
                                                        onClick={() => handleDeleteAlertModalOpen(item?.payload?.__auto_id__)}
                                                    >
                                                        <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                                    </span>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                                {!loading && allTokens?.length === 0 && (
                                    <TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer >

                    {allTokens?.length > 0 &&
                        (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box marginLeft={'20px'}>
                                    <Select
                                        value={tokenDatarowsPerPage}
                                        onChange={handleTokenDataRowsPerPageChange}
                                        sx={{ width: '110px', height: '35px' }}
                                    >
                                        <MenuItem value={'5'}>5 rows</MenuItem>
                                        <MenuItem value={'10'}>10 rows</MenuItem>
                                        <MenuItem value={'15'}>15 rows</MenuItem>
                                        <MenuItem value={'20'}>20 rows</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Pagination
                                count={totalTokenDataPages}
                                page={tokenDataPage}
                                onChange={handleTokenDataPage}
                                color="standard"
                            />
                        </Box>)
                    }

                </Paper>
            </Box>

            <Modal open={deleteData.modalstatus} onClose={handleDeleteAlertModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={mainStyles.modalcontainer}>
                    <Box sx={mainStyles.mainWarning}>Are you sure you want to delete this token?</Box>
                    <Box sx={mainStyles.subWarning}>Any applications or scripts using this token will no longer be able to access
                        the Ameya API. You cannot undo this action.
                    </Box>
                    <Box style={mainStyles.confirmButtonGroup}>
                        <Button onClick={handleDeleteAlertModalClose} sx={{ ...mainStyles.cancelDelete, textTransform: 'none'}}>Cancel</Button>
                        <Button onClick={() => { handleTokenDelete() }} sx={{ ...mainStyles.confirmDelete, textTransform: 'none',width:'250px' }}>I understand, delete this token</Button>
                    </Box>
                </Box>
            </Modal>

        </Box >
    )
}

export default APiKeys