import { tostAlert } from "./AlertToast";

export const validateFileUpload = async (files, allowedExtensions) => {
    const blockedFiles = [];

    files.forEach(file => {
        const fileExtension = file?.name?.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
            blockedFiles.push(file.name);
        }
    });

    return blockedFiles;
};

export const fileExcistenceCheck = async (existingfiles, selectedFiles) => {
    const matchingFiles = existingfiles?.filter(item1 =>
        selectedFiles?.some(item2 => item1?.file_name === item2?.name)
    )?.map(item => item?.file_name)

    if (matchingFiles.length > 0) {
        if (matchingFiles.length === 1) {
            tostAlert(`File Already Exist:\n\n${matchingFiles.join('\n')}`, 'warning')
            return true
        }
        tostAlert(`Files Already Exist:\n\n${matchingFiles.join('\n')}`, 'warning');
        return true
    }
    return false
}

