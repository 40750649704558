import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { routesConfig } from './Routes';

function ProtectedRoutes() {
    const location = useLocation();
    const currentPath = location.pathname;

    const jwtIdToken = localStorage.getItem('dpod-token');
    if (!jwtIdToken) {
        return <Navigate to="/login" />;
    }

    const matchRoute = (route) => {
        let regexPath = route.path
            .replace(/:\w+/g, '[^/]+')
            .replace(/\/\*$/, '(?:/.*)?');

        const regex = new RegExp(`^${regexPath}$`);
        return regex.test(currentPath);
    };

    const currentRoute = routesConfig.find(matchRoute)

    if (currentRoute) {
        return <Outlet />;
    }
}

export default ProtectedRoutes;