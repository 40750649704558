import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
    Box, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    CircularProgress, MenuItem, Select, Pagination, Tooltip
} from '@mui/material';
import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles';
import { tableStyles } from '../../../styles/tableStyles';
import { buttonStyles } from '../../../styles/buttonStyles';

import { setAuditTestHistoryState } from "../../../Redux/slice/dataSlice";
import { setAuditTestHistoryAdded } from "../../../Redux/slice/newDataSlice";
import AuditTestHistoryData from '../../../utils/ApiFunctions/AuditTestHistory';
import { tostAlert } from '../../../utils/AlertToast';
import AuditToolApi from '../../../Api/Services/AmeyaBackend/AuditToolApi';
import LoadBar from '../../../utils/LoadBar';

function AuditToolPlaygroundHistory() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [testSessionHistory, setTestSessionHistory] = useState([])
    const [testHistoryDataRowsPerPage, setTestHistoryDataRowsPerPage] = useState('20');
    const [testHistoryDataPage, setTestHistoryDataPage] = useState(1);
    const [totalTestHistoryPages, setTotalTestHistoryPages] = useState(1);

    // Redux States
    const audit_test_history = useSelector(state => state.all_data.audit_test_history)
    const audit_test_history_added = useSelector(state => state.data_added.audit_test_history_added)

    useEffect(() => {
        getAuditToolTestHistory()
    }, [audit_test_history_added])

    const getAuditToolTestHistory = async () => {
        setLoading(true)
        try {
            if (audit_test_history?.length > 0 && audit_test_history_added === false) {
                setTestSessionHistory(audit_test_history)
                return
            } else {
                const response = await AuditTestHistoryData();
                setTestSessionHistory(response)
                dispatch(setAuditTestHistoryState(response))
                dispatch(setAuditTestHistoryAdded(false))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setTotalTestHistoryPages(Math.ceil(testSessionHistory?.length / parseInt(testHistoryDataRowsPerPage)));
    }, [testSessionHistory, testHistoryDataRowsPerPage]);

    let PaginatedTestHistoryData = [];
    const handleTestHistoryDataPage = (event, newPage) => { setTestHistoryDataPage(newPage) }

    const handleTestHistoryDataRowsPerPageChange = event => {
        setTestHistoryDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(testSessionHistory?.length / parseInt(newRowsPerPage));
        setTestHistoryDataRowsPerPage(newRowsPerPage);
        setTestHistoryDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalTestHistoryPages(newTotalPages);
    };
    if (testSessionHistory?.length > 0) {
        const startIndex = (testHistoryDataPage - 1) * parseInt(testHistoryDataRowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(testHistoryDataRowsPerPage), testSessionHistory?.length);
        PaginatedTestHistoryData = testSessionHistory?.slice(startIndex, endIndex);
    }

    const handleDocuments = async (item) => {
        setLoading(true)
        try {
            if (item?.pdf_name && item?.json_name) {

                const reqObj = {
                    "json_file_name": item?.json_name,
                    "pdf_file_name": item?.pdf_name,
                }
                const response = await AuditToolApi.qmsCheckCompliance(JSON.stringify(reqObj))
                if (response.status === 200) {
                    dispatch(setAuditTestHistoryAdded(true))
                    tostAlert('Retraining Completed Successfully', 'success')
                    return
                }
                tostAlert('Retraining Failed', 'error')
            } else {
                tostAlert('File Not Selected or Invalid File', 'custom_warning')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box sx={Styles.mainContainer}>

            {loading && (<LoadBar />)}

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={fontStyles.largeText}>Playground</Typography>

                <Button
                    sx={{ ...buttonStyles.primaryBtn, width: '175px', height: '35px' }}
                    onClick={() => navigate('/launch/qms-audit-tool/playground/test')}
                >
                    <Typography style={fontStyles.btnText}>NEW TEST SESSION</Typography>
                </Button>
            </Box>

            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                Test {"AI QMS "}Agent
            </Typography>

            <Box marginTop={'20px'}>
                <Typography sx={{ ...fontStyles.largeText, marginTop: '10px' }}>
                    Test Session History
                </Typography>

                <Box marginTop={'20px'} paddingBottom={'20px'}>
                    <Paper>
                        < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                            <Table
                                stickyHeader sx={{ height: testSessionHistory.length <= 0 ? '400px' : `${testSessionHistory.length}px` }}
                            >
                                <TableHead sx={tableStyles.thead}>
                                    <TableRow>
                                        <TableCell sx={{ ...tableStyles.thcell, width: '20%' }}>
                                            <Typography sx={tableStyles.theadText}>Date</Typography>
                                        </TableCell>
                                        <TableCell sx={{ ...tableStyles.thcell, width: '25%' }}>
                                            <Typography sx={tableStyles.theadText}>Trained By</Typography>
                                        </TableCell>
                                        <TableCell sx={{ ...tableStyles.thcell, width: '45%' }}>
                                            <Typography sx={tableStyles.theadText}>Report / Specifications</Typography>
                                        </TableCell>
                                        <TableCell sx={{ ...tableStyles.thcell, width: '10%' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {testSessionHistory.length === 0 && loading ?
                                        (<TableRow sx={tableStyles.emptytr}>
                                            <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>)
                                        :
                                        (testSessionHistory.length > 0 && PaginatedTestHistoryData.map((item, index) => (
                                            < TableRow key={index} sx={tableStyles.trow}>
                                                <TableCell style={tableStyles.tdcell}>{moment(item?.last_test_date)?.format('DD-MMM-YYYY hh:mm A')}</TableCell>
                                                <TableCell style={tableStyles.tdcell}>John Doe Henry</TableCell>
                                                <TableCell style={tableStyles.tdcell}>
                                                    <Tooltip placement='right-start'
                                                        title={
                                                            <div style={{ padding: "10px" }}>
                                                                <p>
                                                                    {item?.pdf_name?.substring(0, item?.pdf_name?.lastIndexOf('.')) || item?.pdf_name}
                                                                    &nbsp;/&nbsp;
                                                                    {item?.json_name?.substring(0, item?.json_name?.lastIndexOf('.')) || item?.json_name}
                                                                </p>
                                                            </div>
                                                        }
                                                        arrow PopperProps={{
                                                            style: { width: '250px' }
                                                        }}>
                                                        {
                                                            ((item?.pdf_name?.substring(0, item?.pdf_name?.lastIndexOf('.')) || item?.pdf_name) +
                                                                ' / ' +
                                                                (item?.json_name?.substring(0, item?.json_name?.lastIndexOf('.')) || item?.json_name))
                                                                .length > 30
                                                                ? `${((item?.pdf_name?.substring(0, item?.pdf_name?.lastIndexOf('.')) || item?.pdf_name) +
                                                                    ' / ' +
                                                                    (item?.json_name?.substring(0, item?.json_name?.lastIndexOf('.')) || item?.json_name)).substring(0, 50)}...`
                                                                : (item?.pdf_name?.substring(0, item?.pdf_name?.lastIndexOf('.')) || item?.pdf_name) +
                                                                ' / ' +
                                                                (item?.json_name?.substring(0, item?.json_name?.lastIndexOf('.')) || item?.json_name)
                                                        }
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell style={tableStyles.tdcell}>
                                                    <Button
                                                        sx={{ ...buttonStyles.tertiaryBtn, width: '80px', marginLeft: '10px' }}
                                                    >
                                                        <Typography
                                                            sx={{ ...fontStyles.btnSmallText, fontWeight: 'bold' }}
                                                            onClick={() => handleDocuments(item)}
                                                        >RETRAIN</Typography>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )))
                                    }
                                    {!loading && testSessionHistory.length === 0 && (
                                        <TableRow sx={tableStyles.emptytr}>
                                            <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                                No records to display
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer >

                        {testSessionHistory?.length > 0 &&
                            (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box marginLeft={'20px'}>
                                        <Select
                                            value={testHistoryDataRowsPerPage}
                                            onChange={handleTestHistoryDataRowsPerPageChange}
                                            sx={{ width: '110px', height: '35px' }}
                                        >
                                            <MenuItem value={'5'}>5 rows</MenuItem>
                                            <MenuItem value={'10'}>10 rows</MenuItem>
                                            <MenuItem value={'15'}>15 rows</MenuItem>
                                            <MenuItem value={'20'}>20 rows</MenuItem>
                                        </Select>
                                    </Box>
                                </Box>
                                <Pagination
                                    count={totalTestHistoryPages}
                                    page={testHistoryDataPage}
                                    onChange={handleTestHistoryDataPage}
                                    color="standard"
                                />
                            </Box>)
                        }

                    </Paper>
                </Box>
            </Box>

        </Box>
    )
}

export default AuditToolPlaygroundHistory;