import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import { v4 as uuid } from 'uuid';

import {
    Autocomplete, Box, Button, FormControl, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TableSortLabel, CircularProgress, MenuItem, Select, Pagination,

} from '@mui/material';
import { AdjustRounded } from '@mui/icons-material';

import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles';
import { buttonStyles } from '../../../styles/buttonStyles';

import { setCOAFilesState, setSpecificationFilesState } from "../../../Redux/slice/dataSlice";
import { setSpecificationFilesAdded, setCOAFilesAdded } from "../../../Redux/slice/newDataSlice";

import LoadBar from '../../../utils/LoadBar';
import { IconSvg } from '../../../utils/globalIcons';
import { tostAlert } from '../../../utils/AlertToast';
import { tableStyles } from '../../../styles/tableStyles';
import { searchBarStyles } from '../../../styles/searchBarStyles';
import PdfSourceFiles from '../../../utils/ApiFunctions/PdfSourceFiles';
import ChatHandler from './AuditToolChat/ChatHandler';
import JsonSourceFiles from '../../../utils/ApiFunctions/JsonSourceFiles';
import AuditToolApi from '../../../Api/Services/AmeyaBackend/AuditToolApi';
import { fetchTenentId, fetchUserId, getUserName } from '../../../utils/GetAccountDetails';

function AuditToolPlayground() {

    const dispatch = useDispatch();
    const [coafiles, setCOAFiles] = useState([]);
    const [specificationfiles, setSpecificationFiles] = useState([]);
    const [isResubmit, setIsResubmit] = useState(false);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    // Redux States
    const coa_files_data = useSelector(state => state.all_data.coafiles)
    const specification_files_data = useSelector(state => state.all_data.specificationfiles)
    const coa_files_added = useSelector(state => state.data_added.coa_files_added)
    const specification_files_added = useSelector(state => state.data_added.specification_files_added)

    const [selectedpdffile, setSelectedPdfFile] = useState(null)
    const [selectedjsonfile, setSelectedJsonFile] = useState(null)
    const documentprocessdetails = {
        test_id: null,
        total_pass: null,
        total_tests: null,
        test_result: [],
        test_status: 'loading'
    }
    const [documentprocessdata, setDocumentProcessData] = useState(documentprocessdetails)
    const [processDatarowsPerPage, setProcessDataRowsPerPage] = useState('5');
    const [processDataPage, setProcessDataPage] = useState(1);
    const [totalProcessDataPages, setTotalProcessDataPages] = useState(1);

    // QMS Chat Handle
    const [chatMessageLoading, setChatMessageLoading] = useState(false);
    const [chatdrawer, setChatDrawer] = useState({ state: false });

    const [qmsChatData, setQmsChatData] = useState([]);
    const [qmsTestId, setQmsTestId] = useState(null);
    const [userFeedback, setUserFeedback] = useState(false);
    const [testResponse, setTestResponse] = useState({
        test_data: [],
        feedback_thumbs_options: [],
        feedback_chat_options: []
    });

    useEffect(() => {
        getsourceFilesDetails()
    }, [coa_files_added, specification_files_added,])

    const getCoaSourceFiles = async () => {
        if (coa_files_data?.length > 0 && coa_files_added === false) {
            return coa_files_data
        } else {
            const response = await PdfSourceFiles();
            dispatch(setCOAFilesState(response))
            dispatch(setCOAFilesAdded(false))
            return response
        }
    }

    const getSpecificationSourceFiles = async () => {
        if (specification_files_data?.length > 0 && specification_files_added === false) {
            return specification_files_data
        } else {
            const response = await JsonSourceFiles();
            dispatch(setSpecificationFilesState(response))
            dispatch(setSpecificationFilesAdded(false))
            return response
        }
    }

    const getsourceFilesDetails = async () => {
        setLoading(true)
        try {
            const [coaResponse, jsonResponse] = await Promise.all([
                getCoaSourceFiles(),
                getSpecificationSourceFiles()
            ])

            if (coaResponse && coaResponse?.length > 0) {
                const filterd_coa_files = coaResponse?.length > 0 ? coaResponse?.filter(item => item?.status === 'success') : []
                setCOAFiles(filterd_coa_files)
            }

            if (jsonResponse && jsonResponse?.length > 0) {
                const filterd_spec_files = jsonResponse?.length > 0 ? jsonResponse?.filter(item => item?.status === 'success') : []
                setSpecificationFiles(filterd_spec_files)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const toggleDrawer = (open) => (event) => {
        setChatDrawer({ ...chatdrawer, state: open });
    };

    useEffect(() => {
        if (selectedpdffile?.file_name && selectedjsonfile?.file_name && currentFiles?.pdf_file_name && currentFiles?.json_file_name) {
            if (selectedpdffile.file_name === currentFiles.pdf_file_name && selectedjsonfile.file_name === currentFiles.json_file_name) {
                setIsResubmit(true);
            } else {
                setIsResubmit(false);
            }
        } else {
            setIsResubmit(false);
        }
    }, [selectedpdffile, selectedjsonfile, currentFiles]);

    const handlePdfFileSelection = async (event, item) => {
        event.preventDefault()
        setSelectedPdfFile(item)
    }

    const handleJsonFileSelection = async (event, item) => {
        event.preventDefault()
        setSelectedJsonFile(item)
    }

    //Handle Document APi
    const handleDocuments = async () => {
        setLoading(true);
        setIsResubmit(false)
        try {
            if (selectedpdffile && selectedjsonfile) {
                setCurrentFiles({
                    pdf_file_name: selectedpdffile?.file_name,
                    json_file_name: selectedjsonfile?.file_name
                })

                const reqObj = {
                    "json_file_name": selectedjsonfile?.file_name,
                    "pdf_file_name": selectedpdffile?.file_name,
                }
                const response = await AuditToolApi.qmsCheckCompliance(JSON.stringify(reqObj))
                if (response.status === 200) {
                    const reposne_data = response?.data?.results ?? []
                    setDocumentProcessData({
                        ...documentprocessdata,
                        test_id: response?.data?.test_id,
                        test_result: reposne_data?.output?.length > 0 ? reposne_data?.output : [],
                        total_pass: reposne_data?.total_pass,
                        total_tests: reposne_data?.total_tests,
                        test_status: reposne_data?.status_code === 200 || 206 ? 'success' : 'failed'
                    })
                    setIsResubmit(true)
                }
                return
            } else {
                tostAlert('File Not Selected or Invalid File', 'custom_warning')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setTotalProcessDataPages(Math.ceil(documentprocessdata?.test_result?.length / parseInt(processDatarowsPerPage)));
    }, [documentprocessdata?.test_result, processDatarowsPerPage]);

    let PaginatedProcessData = [];
    const handleProcessDataPage = (event, newPage) => { setProcessDataPage(newPage) }

    const handleProcessDataRowsPerPageChange = event => {
        setProcessDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(documentprocessdata?.test_result?.length / parseInt(newRowsPerPage));
        setProcessDataRowsPerPage(newRowsPerPage);
        setProcessDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalProcessDataPages(newTotalPages);
    };
    if (documentprocessdata?.test_result?.length > 0) {
        const startIndex = (processDataPage - 1) * parseInt(processDatarowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(processDatarowsPerPage), documentprocessdata?.test_result?.length);
        PaginatedProcessData = documentprocessdata?.test_result?.slice(startIndex, endIndex);
    }

    const handleClearChatInfo = async () => {
        setChatDrawer({ ...chatdrawer, state: false });
        setQmsChatData([]);
        setQmsTestId(null)
        setTestResponse({ ...testResponse, feedback_thumbs_options: [], feedback_chat_options: [], test_data: [] })
        setUserFeedback(false)
    }

    const handleTrainQmsModel = async (testId) => {
        try {
            setChatMessageLoading(true)

            const systemId = await fetchTenentId();
            const userId = await fetchUserId();
            const userName = await getUserName();

            await handleClearChatInfo()
            setChatDrawer({ ...chatdrawer, state: true });
            setQmsTestId(testId);

            const response = await AuditToolApi.trainQmsModel(testId)
            if (response.status === 200) {
                const responseData = response?.data
                const initiate_chat = responseData?.initate_chat
                const system_message_string = responseData?.system_message;
                const cleanString = system_message_string.trim();
                const cleanedMessage = cleanString.replace(/someUnexpectedChar/g, '');

                const system_message = cleanedMessage ? JSON.parse(cleanedMessage) : null;
                const questions_data = system_message?.questions ?? [];

                if (initiate_chat && questions_data?.length > 0) {
                    const updatedQuestionsData = questions_data?.map((item) => ({ ...item, question_id: uuid(), is_processed: false }));
                    if (updatedQuestionsData?.length > 0) {

                        const promises = updatedQuestionsData.map(async (item) => {

                            let chatFlow = []

                            const systemChat = {
                                sender: 'system',
                                userId: systemId,
                                userName: 'system',
                                testId: testId,
                                questionId: item?.question_id,
                                chat: item?.text,
                                isProcessed: false,
                                blockUserTextInput: true,
                                feedBackOptions: item?.feedback_options,
                                nextUserActionType: item?.next_user_action_type,
                            }
                            chatFlow.push(systemChat);

                            const userChat = {
                                sender: 'user',
                                userId: userId,
                                userName: userName,
                                testId: testId,
                                questionId: item?.question_id,
                                chat: '',
                                isProcessed: false,
                                blockUserTextInput: true,
                                feedBackOptions: item?.feedback_options,
                                nextUserActionType: item?.next_user_action_type,
                            }
                            chatFlow.push(userChat);

                            return chatFlow;
                        });

                        const chatFlows = await Promise.all(promises);
                        const allChatFlows = chatFlows.flat();
                        setQmsChatData((prevChat) => [...prevChat, ...allChatFlows]);
                    }
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }


    const handleQmsChat = async (chat_feedback_type, questionId) => {
        setChatMessageLoading(true)
        try {

            if (qmsChatData?.length > 0) {

                const updatedQmsChatData = qmsChatData.map((item) => {
                    if (item?.questionId === questionId) {

                        if (item?.sender === 'system') {
                            return { ...item, isProcessed: true };
                        }

                        if (item?.sender === 'user') {
                            return { ...item, isProcessed: true, chat: chat_feedback_type };
                        }
                    }
                    return item;
                });

                setQmsChatData(updatedQmsChatData);

                const filterdUpdatedQmsChatData = updatedQmsChatData?.filter((item) => !item?.isProcessed)

                if (filterdUpdatedQmsChatData?.length === 0) {

                    const canReqObjs = [];

                    for (let i = 0; i < updatedQmsChatData.length; i += 2) {
                        const canReqObj = {};

                        if (updatedQmsChatData[i]?.sender === 'system') {
                            canReqObj.system = updatedQmsChatData[i]?.chat;
                        }

                        if (updatedQmsChatData[i + 1]?.sender === 'user') {
                            canReqObj.user = updatedQmsChatData[i + 1]?.chat;
                        }

                        if (canReqObj.system && canReqObj.user) {
                            canReqObjs.push(canReqObj);
                        }
                    }

                    const reqObj = {
                        "test_id": qmsTestId,
                        "chats": canReqObjs
                    }

                    setLoading(true)
                    const response = await AuditToolApi.qmsUserChatMessage(reqObj);
                    if (response.status === 200) {

                        const responseData = response?.data
                        const testOutput = responseData?.results?.output ?? []
                        const feedbackThumbsOptions = responseData?.results?.feedback_options ?? [];

                        if (testOutput?.length === 0 || feedbackThumbsOptions?.length === 0) {
                            const errorMessage = responseData?.error ?? 'Training Failed'
                            tostAlert(errorMessage, 'error')
                            await handleClearChatInfo()
                            return
                        }
                        setTestResponse({ ...testResponse, test_data: testOutput, feedback_thumbs_options: feedbackThumbsOptions })
                        setUserFeedback(true)
                        return
                    }
                    tostAlert('Training Failed', 'error')
                }
            }
        } catch (error) {
            const errorMessage = error?.message ?? 'Training Failed'
            tostAlert(errorMessage, 'error')
            await handleClearChatInfo()
            console.log(error)
        } finally {
            setLoading(false)
            setChatMessageLoading(false)
        }
    };

    const handleUserThumbsFeedback = async (feed_back_type) => {
        setChatMessageLoading(true)

        setTestResponse({ ...testResponse, feedback_thumbs_options: [feed_back_type] })

        if (feed_back_type === "thumbs_up") {
            await handleClearChatInfo()
            tostAlert('Training Completed Successfully', 'success')
            return
        }

        try {
            const reqObj = {
                "test_id": qmsTestId,
                "thumbs_ups": feed_back_type
            }
            const response = await AuditToolApi.qmsUserChatMessage(reqObj);
            if (response.status === 200) {

                const responseData = response?.data
                const feedbackChatsOptions = responseData?.results?.feedback_options ?? [];

                if (feedbackChatsOptions?.length === 0) {
                    const errorMessage = responseData?.error ?? 'Training Failed'
                    tostAlert(errorMessage, 'error')
                    await handleClearChatInfo()
                    return
                }

                setTestResponse({ ...testResponse, feedback_chat_options: feedbackChatsOptions, feedback_thumbs_options: [] })
                return
            }
            tostAlert('Training Failed', 'error')
        } catch (error) {
            const errorMessage = error?.message ?? 'Training Failed'
            tostAlert(errorMessage, 'error')
            await handleClearChatInfo()
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    const handleUserChatFeedback = async (feed_back_type) => {
        setChatMessageLoading(true)
        setTestResponse({ ...testResponse, feedback_chat_options: [feed_back_type] })

        if (feed_back_type === "exit") {
            await handleClearChatInfo()
            tostAlert('Training Completed Successfully', 'success')
            return
        }

        try {
            const reqObj = {
                "test_id": qmsTestId,
                "user_action": feed_back_type
            }
            const response = await AuditToolApi.qmsUserChatMessage(reqObj);
            if (response.status === 200) {
                const responseData = response?.data
                const testOutput = responseData?.results?.output ?? []
                const feedbackThumbsOptions = responseData?.results?.feedback_options ?? [];

                if (testOutput?.length === 0 || feedbackThumbsOptions?.length === 0) {
                    const errorMessage = responseData?.error ?? 'Training Failed'
                    tostAlert(errorMessage, 'error')
                    await handleClearChatInfo()
                    return
                }
                setTestResponse({ ...testResponse, test_data: testOutput, feedback_chat_options: [], feedback_thumbs_options: feedbackThumbsOptions })
                return
            }
            tostAlert('Training Failed', 'error')
        } catch (error) {
            const errorMessage = error?.message ?? 'Training Failed'
            tostAlert(errorMessage, 'error')
            await handleClearChatInfo()
            console.log(error)
        } finally {
            setChatMessageLoading(false)
        }
    }

    return (
        <Box sx={Styles.mainContainer}>
            {loading && (<LoadBar />)}

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={fontStyles.largeText}>Playground</Typography>

                <Button sx={{ ...buttonStyles.primaryBtn, width: '100px', height: '35px' }}
                    disabled={loading}
                    onClick={() => handleDocuments()}
                >
                    {
                        isResubmit ?
                            (<Typography style={fontStyles.btnText}>RESUBMIT</Typography>)
                            :
                            (<Typography style={fontStyles.btnText}>SUBMIT</Typography>)
                    }
                </Button>
            </Box>

            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                Test AI QMS Agent
            </Typography>

            <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>

                <FormControl>
                    <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Select a document</Typography>
                    <Autocomplete
                        sx={{ ...searchBarStyles.searchBar, width: '300px' }}
                        options={coafiles}
                        getOptionLabel={(option) => option?.file_name}
                        id="clear-on-escape"
                        clearOnEscape
                        value={selectedpdffile}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Document" />
                        )}
                        onChange={handlePdfFileSelection}
                    />
                </FormControl>

                <FormControl sx={{ marginLeft: '10px' }}>
                    <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Select a specification</Typography>
                    <Autocomplete
                        sx={{ ...searchBarStyles.searchBar, width: '300px' }}
                        options={specificationfiles}
                        getOptionLabel={(option) => option?.file_name}
                        id="clear-on-escape"
                        clearOnEscape
                        value={selectedjsonfile}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Specification" />
                        )}
                        onChange={handleJsonFileSelection}
                    />
                </FormControl>

            </Box>

            {documentprocessdata?.test_status === "success" &&
                <Box marginTop={'30px'} paddingBottom={'20px'}>

                    <Typography sx={fontStyles.mediumText}>
                        Results
                    </Typography>

                    <Box marginTop={'20px'} paddingBottom={'20px'}>
                        <Paper>
                            < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                                <Table
                                    stickyHeader sx={{ height: documentprocessdata?.test_result?.length <= 0 ? '400px' : `${documentprocessdata?.test_result?.length}px` }}
                                >
                                    <TableHead sx={tableStyles.thead}>
                                        <TableRow>
                                            <TableCell sx={{ ...tableStyles.thcell, width: '30%' }}>
                                                <TableSortLabel sx={tableStyles.sortlabel}
                                                // active={productionfilesortKey === 'payload.productionfile_name.en'}
                                                // direction={productionfilesortKey === 'payload.productionfile_name.en' ? productionfilesortDirection : 'asc'}
                                                // onClick={() => handleProductionFileSort('payload.productionfile_name.en')}
                                                >
                                                    <Typography sx={tableStyles.theadText}>Specification</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ ...tableStyles.thcell, width: '30%' }}>
                                                <TableSortLabel sx={tableStyles.sortlabel}
                                                // active={productionfilesortKey === 'payload.created_on'}
                                                // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                                // onClick={() => handleProductionFileSort('payload.created_on')}
                                                >
                                                    <Typography sx={tableStyles.theadText}>Report Value</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ ...tableStyles.thcell, width: '40%' }}>
                                                <TableSortLabel sx={tableStyles.sortlabel}
                                                // active={productionfilesortKey === 'payload.created_on'}
                                                // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                                // onClick={() => handleProductionFileSort('payload.created_on')}
                                                >
                                                    <Typography sx={tableStyles.theadText}>Result</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {documentprocessdata?.test_result?.length === 0 && loading ?
                                            (<TableRow sx={tableStyles.emptytr}>
                                                <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>)
                                            :
                                            (documentprocessdata?.test_result?.length > 0 && PaginatedProcessData?.map((item, index) => (
                                                < TableRow key={index} sx={tableStyles.trow}>
                                                    <TableCell style={tableStyles.tdcell}>{item?.test_name}</TableCell>
                                                    <TableCell style={tableStyles.tdcell}>
                                                        {item?.status === "pass" ?
                                                            <Box>{item?.result_value}&nbsp;{item?.uom}</Box>
                                                            : <Typography sx={{ ...fontStyles.smallText, color: "#C30E2E", fontWeight: 'bold' }}>UNKNOWN</Typography>}
                                                    </TableCell>
                                                    <TableCell style={tableStyles.tdcell}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                                                            {item?.status === "pass" ?
                                                                (<Box marginRight={'30px'}>
                                                                    <ReactSVG src={IconSvg.resultCorrectIcon} />
                                                                </Box>) :
                                                                (<Box marginRight={'30px'} display={'flex'} alignItems={'center'}>
                                                                    <ReactSVG src={IconSvg.resultErrorIcon} />
                                                                    <Button
                                                                        sx={{ ...buttonStyles.tertiaryBtn, width: '80px', marginLeft: '10px' }}
                                                                        onClick={() => handleTrainQmsModel(documentprocessdata.test_id)}
                                                                    >
                                                                        <Typography style={{ ...fontStyles.btnSmallText, fontWeight: 'bold' }}>Train</Typography>
                                                                    </Button>
                                                                </Box>)
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )))
                                        }
                                        {!loading && documentprocessdata?.test_result?.length === 0 && (
                                            <TableRow sx={tableStyles.emptytr}>
                                                <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                                    No records to display
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>

                                </Table>
                            </TableContainer >

                            {documentprocessdata?.test_result?.length > 0 &&
                                (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box marginLeft={'20px'}>
                                            <Select
                                                value={processDatarowsPerPage}
                                                onChange={handleProcessDataRowsPerPageChange}
                                                sx={{ width: '110px', height: '35px' }}
                                            >
                                                <MenuItem value={'5'}>5 rows</MenuItem>
                                                <MenuItem value={'10'}>10 rows</MenuItem>
                                                <MenuItem value={'15'}>15 rows</MenuItem>
                                                <MenuItem value={'20'}>20 rows</MenuItem>
                                            </Select>
                                        </Box>
                                    </Box>
                                    <Pagination
                                        count={totalProcessDataPages}
                                        page={processDataPage}
                                        onChange={handleProcessDataPage}
                                        color="standard"
                                    />
                                </Box>)
                            }

                        </Paper>
                    </Box>
                </Box>
            }

            {documentprocessdata?.test_status === "failed" &&
                <Box sx={Styles.unProcessDocumentContainer}>
                    <Box padding={'20px'}>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Box marginRight={'10px'}>
                                <AdjustRounded color='error' />
                            </Box>
                            <Typography sx={{ ...fontStyles.smallText, color: 'red' }}>
                                COUD NOT PROCESS DOCUMENT
                            </Typography>
                        </Box>
                        <Box marginTop={'5px'} display={'flex'} justifyContent={'center'}>
                            <Button
                                sx={{ ...buttonStyles.tertiaryBtn, width: '80px' }}
                                onClick={toggleDrawer(true)}
                            >
                                <Typography style={fontStyles.btnSmallText}>TRAIN</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }

            <ChatHandler
                chatdrawer={chatdrawer}
                toggleDrawer={toggleDrawer}
                qmsChatData={qmsChatData}
                handleQmsChat={handleQmsChat}
                chatMessageLoading={chatMessageLoading}
                testResponse={testResponse}
                userFeedback={userFeedback}
                handleUserThumbsFeedback={handleUserThumbsFeedback}
                handleUserChatFeedback={handleUserChatFeedback}
            />

        </Box >
    )
}

export default AuditToolPlayground;