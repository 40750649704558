import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";

import { Typography } from '@mui/material';
import { Styles } from "./Styles"
import './Styles.css';

import { IconSvg } from '../../../utils/globalIcons';


function SubSidebar({ handleMenuChange }) {

  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const selected_space = useSelector(state => state.current_selected_data.selected_space)
  const selected_project = useSelector(state => state.current_selected_data.selected_project)

  useEffect(() => {
    const selected_engine_name = selected_project?.payload?.configuration?.engine_name
    handleMenuChange(selected_engine_name)
  }, [selected_project])

  const menuItems = [
    { icon: IconSvg.playgroundIcon, label: 'Playground', engine_name: "", icon_type: "svg", path: 'playground' },
    { icon: IconSvg.apiIcon, label: 'Api', engine_name: "", icon_type: "svg", path: 'api' },
  ];

  const filteredMenuItems = () => {
    let items = []
    if (selected_space && selected_project) {
      const selected_engine_name = selected_project?.payload?.configuration?.engine_name
      const selected_sidebar_items = selected_project?.payload?.configuration?.engine_config?.sidebar_items ?? {}
      const sub_sidebar_items = selected_sidebar_items?.['launch'] ?? [];
      const filteredMenuItems = menuItems?.filter(item => sub_sidebar_items?.map(item => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.includes(item?.label));
      filteredMenuItems.forEach(item => { item.engine_name = selected_engine_name });
      items = filteredMenuItems;
    }
    return items;
  };

  const handleMenuItemClick = (index, engine, path) => {
    setSelectedMenuItem(index);
    handleMenuChange(engine, path)
  };

  return (

    <nav style={Styles.sidebar}>
      <ul className="menu">
        {filteredMenuItems()?.map((item, index) => (
          <li key={index}
            className={`launch-menu-item ${selectedMenuItem === index ? 'active' : ''}`}
            onClick={() => handleMenuItemClick(index, item.engine_name, item.path)}
          >
            <span className="launch-icon">{
              item.icon_type === "svg" ?
                (<ReactSVG
                  src={item.icon}
                  className={`sidebar_icon ${selectedMenuItem === index ? 'active' : ''}`}
                />) :
                (<>{item.icon}</>)
            }
            </span>
            <Typography className="launch-label" variant="caption">{item.label}</Typography>
          </li>
        ))}

      </ul>
    </nav >
  );
}

export default SubSidebar;