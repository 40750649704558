// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete_icon svg {
    color: #C30E2E;
    transition: color 0.3s ease;
    stroke: #C30E2E;
    transition: stroke 0.3s ease;
}

.delete_icon:hover svg {
    color: #C30E2E;
    stroke: #C30E2E;
}

.rag_file_icon svg {
    color: #000000;
    transition: color 0.3s ease;
    stroke: #000000;
    transition: stroke 0.3s ease;
}

.upload_success_icon svg {
    color: #25B93F;
    transition: color 0.3s ease;
    stroke: #25B93F;
    transition: stroke 0.3s ease;
}

.upload_success_icon:hover svg {
    color: #25B93F;
    stroke: #25B93F;
}

.upload_proccess_icon svg {
    color: #FBBC04;
    transition: color 0.3s ease;
    stroke: #FBBC04;
    transition: stroke 0.3s ease;
}

.upload_proccess_icon:hover svg {
    color: #FBBC04;
    stroke: #FBBC04;
}


.upload_failed_icon svg {
    color: #C30E2E;
    transition: color 0.3s ease;
    stroke: #C30E2E;
    transition: stroke 0.3s ease;
}

.upload_failed_icon:hover svg {
    color: #C30E2E;
    stroke: #C30E2E;
}`, "",{"version":3,"sources":["webpack://./src/containers/DataSource/AuditTool/Styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;;AAGA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".delete_icon svg {\n    color: #C30E2E;\n    transition: color 0.3s ease;\n    stroke: #C30E2E;\n    transition: stroke 0.3s ease;\n}\n\n.delete_icon:hover svg {\n    color: #C30E2E;\n    stroke: #C30E2E;\n}\n\n.rag_file_icon svg {\n    color: #000000;\n    transition: color 0.3s ease;\n    stroke: #000000;\n    transition: stroke 0.3s ease;\n}\n\n.upload_success_icon svg {\n    color: #25B93F;\n    transition: color 0.3s ease;\n    stroke: #25B93F;\n    transition: stroke 0.3s ease;\n}\n\n.upload_success_icon:hover svg {\n    color: #25B93F;\n    stroke: #25B93F;\n}\n\n.upload_proccess_icon svg {\n    color: #FBBC04;\n    transition: color 0.3s ease;\n    stroke: #FBBC04;\n    transition: stroke 0.3s ease;\n}\n\n.upload_proccess_icon:hover svg {\n    color: #FBBC04;\n    stroke: #FBBC04;\n}\n\n\n.upload_failed_icon svg {\n    color: #C30E2E;\n    transition: color 0.3s ease;\n    stroke: #C30E2E;\n    transition: stroke 0.3s ease;\n}\n\n.upload_failed_icon:hover svg {\n    color: #C30E2E;\n    stroke: #C30E2E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
