import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    spaces: [],
    appflyte_engines: [],
    appflyte_functions: [],
    appflyte_event_types: [],
    projects: [],
    agent_api_tokens: [],
    project_source_folders: [],
    coafiles: [],
    specificationfiles: [],
    audit_test_history: [],
    docsearchfiles: [],
    relam_settings: []
};

const dataSlice = createSlice({
    name: 'all_data',
    initialState,
    reducers: {

        // Spaces
        setSpacesState(state, action) {
            state.spaces = action.payload;
        },

        // Engines
        setAppflyteEngineState(state, action) {
            state.appflyte_engines = action.payload;
        },

        // Functions
        setAppflyteFunctionState(state, action) {
            state.appflyte_functions = action.payload;
        },

        // Event Types
        setAppflyteEventTypesState(state, action) {
            state.appflyte_event_types = action.payload;
        },

        // Projects
        setProjectsState(state, action) {
            state.projects = action.payload;
        },

        // AgentAPiToken
        setAgentApiTokenState(state, action) {
            state.agent_api_tokens = action.payload;
        },

        // SourceFiles
        setProjectSourceFolderState(state, action) {
            state.project_source_folders = action.payload;
        },
        setCOAFilesState(state, action) {
            state.coafiles = action.payload;
        },
        setSpecificationFilesState(state, action) {
            state.specificationfiles = action.payload;
        },
        setAuditTestHistoryState(state, action) {
            state.audit_test_history = action.payload;
        },

        // Doc Search Files
        setDocSearchFilesState(state, action) {
            state.docsearchfiles = action.payload;
        },
        setRelamSettingState(state, action) {
            state.relam_settings = action.payload;
        },
    },
});

export const {
    setSpacesState,
    setAppflyteEngineState,
    setAppflyteFunctionState,
    setAppflyteEventTypesState,
    setProjectsState,
    setAgentApiTokenState,
    setProjectSourceFolderState,
    setCOAFilesState,
    setSpecificationFilesState,
    setAuditTestHistoryState,
    setDocSearchFilesState,
    setRelamSettingState
} = dataSlice.actions;

export default dataSlice.reducer;
