import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selected_space: null,
    selected_project: null
};

const currentSelectedDataSlice = createSlice({
    name: 'selected_data',
    initialState,
    reducers: {
        setSelectedSpace(state, action) { state.selected_space = action.payload },
        setSelectedProject(state, action) { state.selected_project = action.payload }
    },
});

export const { setSelectedSpace, setSelectedProject } = currentSelectedDataSlice.actions;

export default currentSelectedDataSlice.reducer;
