// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete_icon svg {
    color: #C30E2E;
    transition: color 0.3s ease;
    stroke: #C30E2E;
    transition: stroke 0.3s ease;
}

.delete_icon:hover svg {
    color: #C30E2E;
    stroke: #C30E2E;
}

.trained_icon svg {
    color: #25B93F;
    transition: color 0.3s ease;
    stroke: #25B93F;
    transition: stroke 0.3s ease;
}

.trained_icon:hover svg {
    color: #25B93F;
    stroke: #25B93F;
}

.training_icon svg {
    color: #FBBC04;
    transition: color 0.3s ease;
    stroke: #FBBC04;
    transition: stroke 0.3s ease;
}

.training_icon:hover svg {
    color: #FBBC04;
    stroke: #FBBC04;
}

.search_file_icon svg {
    color: #000000;
    transition: color 0.3s ease;
    stroke: #000000;
    transition: stroke 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/containers/DataSource/LLMDocSearch/Styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;AAChC","sourcesContent":[".delete_icon svg {\n    color: #C30E2E;\n    transition: color 0.3s ease;\n    stroke: #C30E2E;\n    transition: stroke 0.3s ease;\n}\n\n.delete_icon:hover svg {\n    color: #C30E2E;\n    stroke: #C30E2E;\n}\n\n.trained_icon svg {\n    color: #25B93F;\n    transition: color 0.3s ease;\n    stroke: #25B93F;\n    transition: stroke 0.3s ease;\n}\n\n.trained_icon:hover svg {\n    color: #25B93F;\n    stroke: #25B93F;\n}\n\n.training_icon svg {\n    color: #FBBC04;\n    transition: color 0.3s ease;\n    stroke: #FBBC04;\n    transition: stroke 0.3s ease;\n}\n\n.training_icon:hover svg {\n    color: #FBBC04;\n    stroke: #FBBC04;\n}\n\n.search_file_icon svg {\n    color: #000000;\n    transition: color 0.3s ease;\n    stroke: #000000;\n    transition: stroke 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
