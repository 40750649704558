import DocumentSearchApi from "../../Api/Services/AmeyaBackend/DocumentSearchApi";

const DocSearchFilesData = async (reqObj) => {

    const getAllDocSearchFiles = async (reqObj) => {
        const tempArr = []
        try {
            const response = await DocumentSearchApi.fetchUploadedFiles(reqObj);
            if (response.data) {
                const response_data = response?.data ?? []
                const result = response_data?.result ?? []
                tempArr.push(...result)
            }
            return tempArr;
        }
        catch (error) {
            console.error(error)
        }
    }

    return getAllDocSearchFiles(reqObj);
};

export default DocSearchFilesData;
