// export const Styles = {
//     sidebar: {
//         height: '100vh',
//         width: '180px',
//         background: '#F5F7FB',
//         position: 'fixed',
//         boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
//         color: '#000000',
//         flexShrink: 0
//     },
//     componentContainer: {
//         backgroundColor: '#FFFFFF',
//         flex: 1,
//         overflow: 'auto',
//         marginTop: '0',
//         marginLeft: '180px',
//         padding: '20px',
//     }
// }

export const Styles = {
    mainContainer: {
        width: '100%',
        height: '100vh',
    },
    sidebar: {
        width: '180px',
        height: '100vh',
        flexShrink: 0,
        position: 'fixed'
    },
    componentContainer: {
        backgroundColor: '#FFFFFF',
        marginLeft: '180px',
        padding: '20px'
    }
}