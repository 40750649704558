import { AxiosLLMDocSearchObj } from "../../Configurations/axios-ameya-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"

class ameyaBackendApi {

    constructor() {
        this.accountId = null;
        this.subscribeId = null;
        this.subscriptionId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscriberId = await fetchSubscriberId();
        this.subscriptionId = await fetchSubscriptionId();
    }

    uploadDraftPdfFile = async (reqObj) => {
        await this.initialize();
        return AxiosLLMDocSearchObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/adddraftdocument/event/documentsearch_draft_upload`, reqObj)
    }

    uploadPublishPdfFile = async (reqObj) => {
        await this.initialize();
        return AxiosLLMDocSearchObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/addpublisheddocuent/event/documentsearch_publish_upload`, reqObj)
    }

    deleteUploadedFiles = async (reqObj) => {
        await this.initialize();
        return AxiosLLMDocSearchObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/archivedocument/event/documentsearch_archive`, reqObj)
    }

    fetchUploadedFiles = async (reqObj) => {
        await this.initialize();
        return AxiosLLMDocSearchObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/getdocument/event/documentsearch_get_files`, reqObj)
    }

    documentSearchQuery = async (reqObj) => {
        await this.initialize();
        return AxiosLLMDocSearchObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/searchdocument/event/documentsearch_search`, reqObj)
    }

    llmDocumentSearchQuery = async (reqObj) => {
        await this.initialize();
        return AxiosLLMDocSearchObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/searchdocument/event/documentsearch_search`, reqObj)
    }

    fileVersionChange = async (reqObj) => {
        await this.initialize();
        return AxiosLLMDocSearchObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/function/managedocuments/revertversion/event/documentsearch_revert_published_version`, reqObj)
    }

}

const LLMDocumentSearchApi = new ameyaBackendApi();
export default LLMDocumentSearchApi;


