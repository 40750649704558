import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { TextField, Button, Box, Typography, CircularProgress, IconButton } from '@mui/material';
import { Styles } from './Styles';
import "./Styles.css"
import { buttonStyles } from '../../../styles/buttonStyles';
import { fontStyles } from '../../../styles/fontStyles';
import { getUserName } from '../../../utils/GetAccountDetails';
import AmeyaSettingsApi from '../../../Api/Services/AppflyteBackend/AmeyaSettings';
import { tostAlert } from '../../../utils/AlertToast';
import LoadBar from '../../../utils/LoadBar';
import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';
import { ReactSVG } from 'react-svg';
import { IconSvg } from '../../../utils/globalIcons';
import { Add } from '@mui/icons-material';

function RealmSettings() {
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [realmAttributes, setRealmAttributes] = useState([]);
    const [newKey, setNewKey] = useState("");
    const [isExistingData, setIsExistingData] = useState(false);
    const settingsDetails = { item_id: null, update_key: null };
    const [settingsData, setSettingsData] = useState(settingsDetails);
    const [llmSettings, setLLMSettings] = useState({ api_keys: "", llm_model: 'openai' })

    const selected_space = useSelector(state => state.current_selected_data.selected_space);
    const selected_project = useSelector(state => state.current_selected_data.selected_project);

    useEffect(() => {
        fetchSettingsData();
    }, []);

    const fetchSettingsData = async () => {
        setInitialLoading(true);
        try {
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];
                const llm_settings = responseData?.payload?.settings?.llm_settings ?? {};
                if (realm_settings?.length > 0 || llm_settings) {
                    setRealmAttributes(realm_settings || []);
                    setLLMSettings({ ...llmSettings, api_keys: llm_settings?.api_keys ?? "", llm_model: llm_settings?.llm_model ?? "" })
                    setSettingsData({ ...settingsData, item_id: responseData?.payload.__auto_id__, update_key: responseData?.update_key });
                    setIsExistingData(true);
                }
            }
        } catch (error) {
            console.error('Error fetching settings data:', error);
        } finally {
            setInitialLoading(false);
        }
    };

    const addKey = () => {
        if (!newKey.trim()) {
            tostAlert('Key cannot be empty', 'warning');
            return;
        }
        if (realmAttributes.includes(newKey.trim())) {
            tostAlert('Key already exists', 'warning');
            return;
        }
        const updatedKeys = [...realmAttributes, newKey.trim()];
        setRealmAttributes(updatedKeys);
        setNewKey("");
    };

    const removeKey = (keyToRemove) => {
        const updatedKeys = realmAttributes.filter(key => key !== keyToRemove);
        setRealmAttributes(updatedKeys);
    };

    const saveSettings = async () => {
        setLoading(true);
        try {
            const userName = await getUserName();
            const settingsObject = {
                collection_item: {
                    workspace_id: selected_space?.payload?.__auto_id__,
                    project_id: selected_project?.payload?.__auto_id__,
                    created_by: userName,
                    created_on: moment().format("DD-MM-YYYY HH:mm:ss"),
                    settings: {
                        "realm_attributes": realmAttributes,
                        "llm_settings": {
                            "llm_model": llmSettings.llm_model,
                            "api_keys": llmSettings.api_keys
                        }
                    }
                }
            };

            let response;
            if (isExistingData && settingsData.item_id && settingsData.update_key) {
                const dataObject = {
                    id: settingsData.item_id,
                    fields: [
                        {
                            path: '$.settings',
                            value: {
                                "realm_attributes": realmAttributes,
                                "llm_settings": {
                                    "llm_model": llmSettings.llm_model,
                                    "api_keys": llmSettings.api_keys
                                }
                            }
                        }
                    ]
                };
                response = await AmeyaSettingsApi.updateSettings(JSON.stringify(dataObject), settingsData.item_id, settingsData.update_key);
            } else {
                response = await AmeyaSettingsApi.addSettings(JSON.stringify(settingsObject));
            }

            if (response.status === 200) {
                tostAlert('Realm Configured Successfully', 'success');
                fetchSettingsData();
            } else {
                tostAlert('Realm Configuration Failed', 'error');
            }
        } catch (error) {
            tostAlert(error?.message, 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const renderTree = (nodes, level = 0) => {
        return nodes.map((node, index) => (
            <Box key={index} sx={{ paddingLeft: `${level * 20}px`, marginTop: '5px' }}>
                <Typography sx={{ ...fontStyles.smallText }}>
                    {node.name}
                </Typography>
                {node.children && node.children.length > 0 && renderTree(node.children, level + 1)}
            </Box>
        ));
    };

    return (
        <Box sx={Styles.mainContainer}>
            {(loading || initialLoading) && <LoadBar />}

            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={fontStyles.largeText}>Realm Settings</Typography>
                <Button
                    onClick={saveSettings}
                    variant="contained"
                    color="primary"
                    disabled={loading || initialLoading}
                    sx={{ ...buttonStyles.primaryBtn, width: '100px' }}>
                    SAVE
                </Button>
            </Box>

            {initialLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 100px)' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                        Add field and Configure
                    </Typography>

                    <Box display="flex" alignItems="center" marginBottom="20px">
                        <TextField
                            value={newKey}
                            onChange={(e) => setNewKey(e.target.value)}
                            placeholder="Enter a new key (e.g., ClientId.SiteId)"
                            size="small"
                            sx={{ width: '50%', marginRight: '10px' }}
                        />
                        <IconButton
                            onClick={addKey}
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{
                                marginLeft: '10px',
                                backgroundColor: "#0B51C5",
                                color: "#FFFFFF",
                                '&:hover': {
                                    backgroundColor: "#0B51C5",
                                    color: "#FFFFFF",
                                }
                            }}>
                            <Add />
                        </IconButton>
                    </Box>

                    <Box display={'flex'} alignItems={'center'}>
                        {realmAttributes.length > 0 ? (realmAttributes.map((key, index) => (

                            < Box key={index} marginTop="20px" display={'flex'} alignItems={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'lightgray'} color={'white'} borderRadius={'20px'}>
                                <Typography sx={fontStyles.smallText}>{key}</Typography>
                                <Box marginLeft={'10px'}
                                    onClick={() => removeKey(key)}>
                                    <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                </Box>
                            </Box>

                        )))
                            : (
                                <Typography sx={{ ...fontStyles.smallText, color: 'gray' }}>
                                    No Data added yet.
                                </Typography>
                            )}
                    </Box>
                </>
            )}
        </Box>
    );

}

export default RealmSettings;
