import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { Box, Button, Card, CardContent, Divider, Grid2 as Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Styles } from './Styles';
import { Add, Search } from '@mui/icons-material';

import { fontStyles } from '../../../styles/fontStyles';
import { buttonStyles } from '../../../styles/buttonStyles';
import LoadBar from '../../../utils/LoadBar';

import { fetchTenentId, fetchUserId } from '../../../utils/GetAccountDetails';
import LLMDocumentSearchApi from '../../../Api/Services/AmeyaBackend/LLMDocumentSearchApi';
import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';
import { tostAlert } from '../../../utils/AlertToast';
import { IconSvg } from '../../../utils/globalIcons';
import { ReactSVG } from 'react-svg';

function LLMDocSearchPlayground() {

    const [initialLoading, setInitialLoading] = useState(false)
    const [realmSettings, setRealmSettings] = useState([]);
    const [selectedKey, setSelectedKey] = useState('');
    const [values, setValues] = useState({});
    const [savedValues, setSavedValues] = useState({});

    const [docSearchItem, setDocSearchItem] = useState('');
    const llmsearchDetails = { sources: [], answers: null, relatedQuestions: [] }
    const [llmDocSearchData, setLLMDocSearchData] = useState(llmsearchDetails);
    const [docSearchLoading, setDocSearchLoading] = useState(false);

    // Redux States
    const selected_project = useSelector(state => state.current_selected_data.selected_project)

    useEffect(() => {
        fetchSettingsData();
    }, []);

    const fetchSettingsData = async () => {
        setInitialLoading(true);
        try {
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];

                if (realm_settings?.length > 0) {
                    setRealmSettings(realm_settings);
                }
            }
        } catch (error) {
            console.error('Error fetching settings data:', error);
        } finally {
            setInitialLoading(false);
        }
    };

    const handleSelectChange = (event) => {
        setSelectedKey(event.target.value);
    };

    const handleValueChange = (event) => {
        setValues((prev) => ({
            ...prev,
            [selectedKey]: event.target.value,
        }));
    };

    const handleSaveValue = () => {
        if (selectedKey && values[selectedKey]) {
            setSavedValues((prev) => ({
                ...prev,
                [selectedKey]: values[selectedKey],
            }));
            return
        }
        tostAlert("Value not added", 'warning')
    };

    const handleRemoveValue = (remove_key) => {
        if (remove_key) {
            setSavedValues((prev) => {
                const newSavedValues = { ...prev };
                delete newSavedValues[remove_key];
                return newSavedValues;
            });
        }
    };

    const handleDocumentSearch = async (searchQuery) => {
        if (!searchQuery?.trim()) {
            setLLMDocSearchData(llmsearchDetails);
            return;
        }

        try {
            setDocSearchLoading(true)
            const userId = await fetchUserId();
            const tenentId = await fetchTenentId();

            const reqObj = {
                "query": searchQuery,
                "user_id": userId,
                "realm": Object.keys(savedValues)?.length > 0 ? savedValues : {},
                "project_id": selected_project?.payload?.__auto_id__,
                "state": "Draft"
            }

            const response = await LLMDocumentSearchApi.llmDocumentSearchQuery(JSON.stringify(reqObj));
            if (response.status === 200) {
                const responseData = response?.data
                const result = responseData?.result ?? []
                const response_sources = result?.sources ?? []
                const response_answers = result?.answer ?? null;
                const response_related_questions = result?.related_questions ?? []

                setLLMDocSearchData({
                    ...llmsearchDetails,
                    sources: response_sources,
                    answers: response_answers,
                    relatedQuestions: response_related_questions
                })

            }
        } catch (error) {
            console.log(error)
        } finally {
            setDocSearchLoading(false)
        }
    }

    const handleDocSearchChange = (e) => {
        setDocSearchItem(e.target.value);
    }

    const handleSourceClick = (item) => {
        if (item) {
            window.open(item, '_blank');
        } else {
            console.error("Invalid item or link not found:", item);
        }
    }

    const handleQuestionClick = (item) => {
        setDocSearchItem(item);
        handleDocumentSearch(item);
    }

    useEffect(() => {
        console.log("docSearchData:", llmDocSearchData)
    }, [llmDocSearchData])

    return (
        <Box sx={Styles.mainContainer}>

            {docSearchLoading && <LoadBar />}

            <Typography sx={fontStyles.largeText}>Playground</Typography>
            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>Test LLM Doc Search project</Typography>

            <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>
                <TextField
                    placeholder='Search'
                    variant="outlined"
                    size='small'
                    sx={{ width: '35%' }}
                    value={docSearchItem}
                    onChange={handleDocSearchChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && docSearchItem.trim()) {
                            handleDocumentSearch(docSearchItem);
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    sx={{ ...buttonStyles.primaryBtn, width: '100px', marginLeft: '10px' }}
                    disabled={docSearchLoading}
                    onClick={() => handleDocumentSearch(docSearchItem)}
                >
                    <Typography sx={fontStyles.btnText}>Search</Typography>
                </Button>
            </Box>

            <Box marginTop={'20px'} >
                <Box>
                    <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>Realm Configurations</Typography>

                    <Box marginTop={'5px'}>
                        <Box display="flex" alignItems="center">
                            <Box>
                                <Select
                                    size="small"
                                    sx={{ width: 350, backgroundColor: '#ffffff' }}
                                    value={selectedKey || ""}
                                    onChange={handleSelectChange}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        All Configurations
                                    </MenuItem>
                                    {realmSettings.map((key, index) => (
                                        <MenuItem key={index} value={key}>
                                            {key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box marginLeft={'10px'}>
                                {selectedKey && (
                                    <Box>
                                        <TextField
                                            value={values[selectedKey] || ''}
                                            onChange={handleValueChange}
                                            placeholder={`Enter value for ${selectedKey}`}
                                            size="small"
                                            sx={{ width: '200px' }}
                                        />
                                    </Box>
                                )}
                            </Box>

                            <Box marginLeft={'10px'}>
                                <Button
                                    variant="contained"
                                    onClick={handleSaveValue}
                                    sx={{ ...buttonStyles.primaryBtn, width: '80px', marginLeft: '10px' }}
                                // disabled={!selectedKey || !values[selectedKey]}
                                >
                                    Add
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ marginTop: '20px', width: '100%' }} display={'flex'} alignItems={'center'}>
                            {Object.entries(savedValues).map(([key, value], index) => (
                                < Box key={index} marginTop="20px" display={'flex'} alignItems={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'lightgray'} color={'white'} borderRadius={'20px'}>
                                    <Typography sx={fontStyles.smallText}>{key}:</Typography>
                                    <Typography sx={{ ...fontStyles.smallText, marginLeft: "5px" }}>{value}</Typography>
                                    <Box marginLeft={'10px'}
                                        onClick={() => handleRemoveValue(key)}>
                                        <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                    </Box>
                                </Box>))}
                        </Box>

                    </Box>
                </Box>
            </Box>


            <Box paddingBottom={'20px'}>

                {
                    !docSearchLoading && llmDocSearchData?.sources?.length === 0 && !llmDocSearchData?.answers && llmDocSearchData?.relatedQuestions?.length === 0 &&
                    <Box marginTop={'200px'} textAlign={'center'}>
                        <Typography sx={{ ...fontStyles.mediumText }}>No results found</Typography>
                    </Box>
                }

                {llmDocSearchData?.sources?.length > 0 && <Box marginTop={'20px'}>
                    <Typography sx={fontStyles.largeText}>Sources</Typography>

                    <Box marginTop={'10px'}>
                        <Grid container spacing={6} alignItems="stretch">
                            {llmDocSearchData?.sources?.length > 0 && llmDocSearchData?.sources?.map((item, index) => (
                                <Grid item xs={6} md={3} key={index}>
                                    <Card sx={Styles.sourceCard}
                                        onClick={() => handleSourceClick(item?.document_url)}>
                                        <CardContent sx={{ padding: '0', height: '100%' }}>
                                            <Box padding={'20px'}>
                                                <Box sx={Styles.aiEngineCardHeader}>
                                                    <Typography sx={fontStyles.mediumText}>
                                                        {item?.document_name}
                                                    </Typography>
                                                </Box>
                                                <Box sx={Styles.aiEngineCardBody}>
                                                    <Typography sx={fontStyles.smallText}>
                                                        score:&nbsp; {item?.score}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>}


                {llmDocSearchData?.answers && (
                    <Box marginTop={'30px'}>
                        <Typography sx={fontStyles.largeText}>Answer</Typography>
                        <Box marginTop={'10px'} marginLeft={'15px'}>
                            <Typography sx={{ ...fontStyles.smallText }}>
                                <ReactMarkdown>{llmDocSearchData?.answers}</ReactMarkdown>
                            </Typography>
                        </Box>
                    </Box>
                )}

                {llmDocSearchData?.relatedQuestions?.length > 0 && (
                    <Box marginTop={'30px'}>
                        <Typography sx={fontStyles.largeText}>Related Questions</Typography>
                        {llmDocSearchData?.relatedQuestions?.map((item, index) => (
                            <Box marginTop={'20px'} key={index}>
                                <Divider sx={{ width: '80%', maxWidth: '600px', marginBottom: '10px' }} />

                                <Box sx={Styles.relQuestions}
                                    onClick={() => handleQuestionClick(item?.question)}>
                                    <Typography sx={fontStyles.smallText}>
                                        {item?.question}
                                    </Typography>
                                    <Add sx={{ marginLeft: '10px' }} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>

        </Box >
    )
}

export default LLMDocSearchPlayground;