import AuditToolApi from "../../Api/Services/AmeyaBackend/AuditToolApi";

const JsonSourceFiles = async () => {

    const getAllJsonSourceFiles = async () => {
        const tempArr = [];
        const page_size = 10;
        let current_page = 1;
        let total_records = 0;
        try {
            do {
                const response = await AuditToolApi.getJsonDataSource(page_size, current_page);

                const response_data = response?.data || {};
                const response_result = response_data?.results || [];

                total_records = response_data?.no_of_records || 0;

                if (response_result.length > 0) {
                    tempArr.push(...response_result);
                } else {
                    break;
                }
                
                current_page++;
            } while (tempArr?.length < total_records)
            return tempArr;
        }
        catch (error) {
            console.error(error)
            return tempArr;
        }
    }
    return getAllJsonSourceFiles();
};

export default JsonSourceFiles;