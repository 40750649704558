import axios from "axios";

const AxiosFileObj = axios.create({
    baseURL: process.env.REACT_APP_APPFLYTE_FILE_API_BASE_URL ?? 'https://api.livgood.in/v1/platform',
    responseType: "json"
})

const requestHandler = async (request) => {
    const JwtToken = JSON.parse(localStorage.getItem("dpod-token"))
    request.headers['Authorization'] = `Bearer ${JwtToken}`;
    request.headers['Content-Type'] = 'application/json';
    return request
}

AxiosFileObj.interceptors.request.use(
    request => requestHandler(request)
)

export default AxiosFileObj;

