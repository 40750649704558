import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';

import {
    Box, Button, CircularProgress, InputAdornment, MenuItem, Pagination, Paper, Select, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography, IconButton
} from '@mui/material';
import { Search, AddCircle } from '@mui/icons-material';

import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles';
import { tableStyles } from '../../../styles/tableStyles';

import { fetchTenentId, fetchUserId } from '../../../utils/GetAccountDetails';
import { buttonStyles } from '../../../styles/buttonStyles';
import LoadBar from '../../../utils/LoadBar';
import { IconSvg } from '../../../utils/globalIcons';
import DocumentSearchApi from '../../../Api/Services/AmeyaBackend/DocumentSearchApi';
import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';
import { tostAlert } from '../../../utils/AlertToast';

function DocSearchPlayground() {

    const [initialLoading, setInitialLoading] = useState(false)
    const [realmSettings, setRealmSettings] = useState([]);
    const [selectedKey, setSelectedKey] = useState('');
    const [values, setValues] = useState({});
    const [savedValues, setSavedValues] = useState({});

    const [docSearchItem, setDocSearchItem] = useState('');
    const [docSearchData, setDocSearchData] = useState([])
    const [docSearchLoading, setDocSearchLoading] = useState(false)
    const [searchDatarowsPerPage, setSearchDataRowsPerPage] = useState('5');
    const [searchDataPage, setSearchDataPage] = useState(1);
    const [totalSearchDataPages, setTotalSearchDataPages] = useState(1);

    // Redux States
    const selected_project = useSelector(state => state.current_selected_data.selected_project)

    useEffect(() => {
        fetchSettingsData();
    }, []);

    const fetchSettingsData = async () => {
        setInitialLoading(true);
        try {
            const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
            if (response?.length > 0) {
                const responseData = response?.[0] ?? [];
                const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];

                if (realm_settings?.length > 0) {
                    setRealmSettings(realm_settings);
                }
            }
        } catch (error) {
            console.error('Error fetching settings data:', error);
        } finally {
            setInitialLoading(false);
        }
    };

    const handleSelectChange = (event) => {
        setSelectedKey(event.target.value);
    };

    const handleValueChange = (event) => {
        setValues((prev) => ({
            ...prev,
            [selectedKey]: event.target.value,
        }));
    };

    const handleSaveValue = () => {
        if (selectedKey && values[selectedKey]) {
            setSavedValues((prev) => ({
                ...prev,
                [selectedKey]: values[selectedKey],
            }));
            return
        }
        tostAlert("Value not added", 'warning')
    };

    const handleRemoveValue = (remove_key) => {
        if (remove_key) {
            setSavedValues((prev) => {
                const newSavedValues = { ...prev };
                delete newSavedValues[remove_key];
                return newSavedValues;
            });
        }
    };


    const handleDocumentSearch = async (searchQuery) => {

        if (!searchQuery.trim()) {
            setDocSearchData([]);
            return;
        }

        try {
            setDocSearchLoading(true)
            const reqObj = {
                "query": searchQuery,
                "realm": Object.keys(savedValues)?.length > 0 ? savedValues : {},
                "project_id": selected_project?.payload?.__auto_id__,
                "state": "Draft"
            }

            const response = await DocumentSearchApi.documentSearchQuery(JSON.stringify(reqObj));
            if (response.status === 200) {
                const responseData = response?.data
                const result = responseData?.result ?? []
                const updatedData = result?.length > 0 ? result?.map(item => {
                    const file_format = item?.document_name?.includes('.')
                        ? item?.document_name?.split('.')?.pop()
                        : null;
                    return { ...item, file_format };
                }) : []
                setDocSearchData(updatedData)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setDocSearchLoading(false)
        }
    }

    const handleDocSearchChange = (e) => {
        setDocSearchItem(e.target.value);
    }

    useEffect(() => {
        setTotalSearchDataPages(Math.ceil(docSearchData?.length / parseInt(searchDatarowsPerPage)));
    }, [docSearchData, searchDatarowsPerPage]);

    let PaginatedDocSearchData = [];
    const handleSearchDataPage = (event, newPage) => { setSearchDataPage(newPage) }

    const handleSearchDataRowsPerPageChange = event => {
        setSearchDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(docSearchData?.length / parseInt(newRowsPerPage));
        setSearchDataRowsPerPage(newRowsPerPage);
        setSearchDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalSearchDataPages(newTotalPages);
    };
    if (docSearchData?.length > 0) {
        const startIndex = (searchDataPage - 1) * parseInt(searchDatarowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(searchDatarowsPerPage), docSearchData?.length);
        PaginatedDocSearchData = docSearchData?.slice(startIndex, endIndex);
    }


    return (
        <Box sx={Styles.mainContainer}>
            {docSearchLoading && initialLoading && <LoadBar />}

            <Typography sx={fontStyles.largeText}>
                Playground
            </Typography>
            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                Test Doc Search project
            </Typography>

            <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>
                <TextField
                    placeholder='Search'
                    variant="outlined"
                    size='small'
                    sx={{ width: '35%' }}
                    value={docSearchItem}
                    onChange={handleDocSearchChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && docSearchItem.trim()) {
                            handleDocumentSearch(docSearchItem);
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    sx={{ ...buttonStyles.primaryBtn, width: '100px', marginLeft: '10px' }}
                    disabled={docSearchLoading || initialLoading}
                    onClick={() => handleDocumentSearch(docSearchItem)}
                >
                    <Typography sx={fontStyles.btnText}>Search</Typography>
                </Button>
            </Box>

            <Box marginTop={'20px'}>
                <Box>
                    <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>Realm Configurations</Typography>

                    <Box marginTop={'5px'}>
                        <Box display="flex" alignItems="center">
                            <Box>
                                <Select
                                    size="small"
                                    sx={{ width: 350, backgroundColor: '#ffffff' }}
                                    value={selectedKey || ""}
                                    onChange={handleSelectChange}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        All Configurations
                                    </MenuItem>
                                    {realmSettings.map((key, index) => (
                                        <MenuItem key={index} value={key}>
                                            {key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box marginLeft={'10px'}>
                                {selectedKey && (
                                    <Box>
                                        <TextField
                                            value={values[selectedKey] || ''}
                                            onChange={handleValueChange}
                                            placeholder={`Enter value for ${selectedKey}`}
                                            size="small"
                                            sx={{ width: '200px' }}
                                        />
                                    </Box>
                                )}
                            </Box>

                            <Box marginLeft={'10px'}>
                                <Button
                                    variant="contained"
                                    onClick={handleSaveValue}
                                    sx={{ ...buttonStyles.primaryBtn, width: '80px', marginLeft: '10px' }}
                                // disabled={!selectedKey || !values[selectedKey]}
                                >
                                    Add
                                </Button>
                            </Box>
                        </Box>


                        <Box sx={{ marginTop: '20px', width: '100%' }} display={'flex'} alignItems={'center'}>
                            {Object.entries(savedValues).map(([key, value], index) => (
                                < Box key={index} marginTop="20px" display={'flex'} alignItems={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'lightgray'} color={'white'} borderRadius={'20px'}>
                                    <Typography sx={fontStyles.smallText}>{key}:</Typography>
                                    <Typography sx={{ ...fontStyles.smallText, marginLeft: "5px" }}>{value}</Typography>
                                    <Box marginLeft={'10px'}
                                        onClick={() => handleRemoveValue(key)}>
                                        <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                    </Box>
                                </Box>))}
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Box marginTop={'30px'} paddingBottom={'20px'}>
                <Paper>
                    < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                        <Table
                            stickyHeader sx={{ height: PaginatedDocSearchData.length <= 0 ? '400px' : `${PaginatedDocSearchData.length}px` }}
                        >
                            <TableHead sx={tableStyles.thead}>
                                <TableRow>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '30%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.productionfile_name.en'}
                                        // direction={productionfilesortKey === 'payload.productionfile_name.en' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.productionfile_name.en')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Document</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '70%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.created_on'}
                                        // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.created_on')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Relevance Score</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    PaginatedDocSearchData?.length === 0 && docSearchLoading ?
                                        (<TableRow sx={tableStyles.emptytr}>
                                            <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>)
                                        :
                                        (PaginatedDocSearchData?.length > 0 && PaginatedDocSearchData?.map((item, index) => (
                                            < TableRow key={index} sx={tableStyles.trow}>
                                                <TableCell style={tableStyles.tdcell}>

                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                                                            <ReactSVG src={IconSvg.filesIcon} />
                                                            {/* {item?.file_format === "pdf" && <ReactSVG src={IconSvg.pdfIcon} />}
                                                            {item?.file_format === "docx" && <ReactSVG src={IconSvg.docIcon} />}
                                                            {item?.file_format === "txt" && <ReactSVG src={IconSvg.txtIcon} />}
                                                            {item?.file_format === "pptx" && <ReactSVG src={IconSvg.pptIcon} />}
                                                            {item?.file_format === "xlsx" && <ReactSVG src={IconSvg.xlsxIcon} />}
                                                            {item?.file_format === "csv" && <ReactSVG src={IconSvg.csvIcon} />}
                                                            {item?.file_format !== "pdf" && item?.file_format !== "txt" && item?.file_format !== "docx" &&
                                                                item?.file_format !== "pptx" && <ReactSVG src={IconSvg.filesIcon} />} */}
                                                        </span>
                                                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            <Tooltip placement='right-start'
                                                                title={
                                                                    <div style={{ padding: "10px" }}>
                                                                        <p>{item?.document_name}</p>
                                                                    </div>
                                                                }
                                                                arrow PopperProps={{
                                                                    style: { width: '250px' }
                                                                }}>
                                                                {item?.document_name?.length > 30 ? `${item?.document_name?.substring(0, 70)}...` : item?.document_name}
                                                            </Tooltip>
                                                        </span>
                                                    </Box>
                                                </TableCell>
                                                <TableCell style={tableStyles.tdcell}>{item?.relavence_score}%</TableCell>
                                            </TableRow>))
                                        )
                                }
                                {!docSearchLoading && PaginatedDocSearchData?.length === 0 && (
                                    <TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer >

                    {docSearchData?.length > 0 &&
                        (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box marginLeft={'20px'}>
                                    <Select
                                        value={searchDatarowsPerPage}
                                        onChange={handleSearchDataRowsPerPageChange}
                                        sx={{ width: '110px', height: '35px' }}
                                    >
                                        <MenuItem value={'5'}>5 rows</MenuItem>
                                        <MenuItem value={'10'}>10 rows</MenuItem>
                                        <MenuItem value={'15'}>15 rows</MenuItem>
                                        <MenuItem value={'20'}>20 rows</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Pagination
                                count={totalSearchDataPages}
                                page={searchDataPage}
                                onChange={handleSearchDataPage}
                                color="standard"
                            />
                        </Box>)
                    }

                </Paper>
            </Box>

        </Box>


    )
}

export default DocSearchPlayground;