import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import { Box, Typography } from '@mui/material'
import { fontStyles } from '../styles/fontStyles';
import { Icon } from '@iconify/react';
import { Styles } from './Styles'

import { getUserRole } from '../utils/getUserRole';
import ameya_logo from "../images/ameya_logo.png"

function Signin() {

    const navigate = useNavigate()
    const location = useLocation()
    const app_name = process.env.REACT_APP_NAME
    const authServerUrl = process.env.REACT_APP_OAUTH_SERVER_URL

    const handleOAuthLogin = () => {
        try {
            localStorage.clear()
            const authUrlWithParams = `${authServerUrl}?app_name=${encodeURIComponent(app_name)}`;
            const popup = window.open(authUrlWithParams, "oauthPopup", "width=500,height=600");
            const interval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(interval);
                    console.log("Popup closed");
                }
            }, 1000);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        const handlePopupMessage = async (event) => {
            const app_origin = window.location.origin
            if (event.origin !== app_origin) {
                console.warn("Invalid origin:", event.origin);
                return;
            }
            if (event.data && event.data.type === "login-success") {
                const { queryParams } = event.data;
                postLogin(queryParams);
            }
        };

        window.addEventListener("message", handlePopupMessage);
        return () => {
            window.removeEventListener("message", handlePopupMessage);
        };
        //eslint-disable-next-line
    }, []);

    const postLogin = async (jwtToken) => {
        try {
            let app_subscribed = []
            const decoded_dpod_token = jwtDecode(jwtToken?.token);

            app_subscribed = decoded_dpod_token?.app_subscribed
            // const user_app_roles = decoded_dpod_token?.app_role
            // const filteredRole = await getUserRole(user_app_roles, app_name)

            if (app_subscribed.length > 0 && app_subscribed.includes(app_name)) {
                localStorage.setItem('dpod-token', JSON.stringify(jwtToken?.token));
                localStorage.setItem('refresh-token', JSON.stringify(jwtToken?.refresh_token));
                const decoded_third_party_token = jwtDecode(decoded_dpod_token?.third_party_token)
                const user_name = decoded_third_party_token?.unique_name || decoded_third_party_token?.email;
                localStorage.setItem('UserName', JSON.stringify(user_name));

                // if (!filteredRole) {
                //     navigate("/login")
                //     localStorage.clear()
                // }
                navigate("/")
            }
        } catch (error) {
            console.error('Error decoding Token:', error);
            navigate("/login")
            localStorage.clear()
        }
    };

    useEffect(() => {
        checkLogin();
        //eslint-disable-next-line
    }, []);

    const checkLogin = async () => {
        const jwtIdToken = localStorage.getItem('dpod-token')
        if (location.pathname !== "/authorized") {
            if (!jwtIdToken) {
                localStorage.clear()
                navigate('/login');
            }
        }
    };

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.body}>
                <Box sx={Styles.signinContainer}>
                    <img src={ameya_logo} height={'85.19px'} width={'250px'} />
                    <Box
                        sx={Styles.signinButton}
                        onClick={() => handleOAuthLogin()}
                    >
                        <Icon icon="flat-color-icons:google" width={'25px'} height={'25px'} />
                        <Typography sx={{ ...fontStyles.largeText, marginLeft: '10px' }}>Continue with Google</Typography>
                    </Box>
                </Box>
            </Box>
            {/* <Box sx={Styles.footer}>
                <Typography sx={fontStyles.smallText}>Terms of Service</Typography>
                <Typography sx={{ ...fontStyles.smallText, marginLeft: '20px' }}>Privacy Policy</Typography>
            </Box> */}
        </Box>
    )
}

export default Signin