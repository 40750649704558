import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box, Breadcrumbs, Button, FormControl, FormControlLabel, FormLabel,
    Link, Radio, RadioGroup, Stack, Typography
} from '@mui/material'
import { NavigateNextRounded } from '@mui/icons-material';

import { breadCrumbsStyles } from '../../../styles/breadCrumbs';
import { fontStyles } from '../../../styles/fontStyles';
import { buttonStyles } from '../../../styles/buttonStyles';
import { mainStyles } from '../../../styles/styles';

import OwnPermissions from './OwnPermissions';
import ServiceAccountPermission from './ServiceAccountPermission';
import { getUserName } from '../../../utils/GetAccountDetails';

import getAppflyteEventTypesData from '../../../utils/ApiFunctions/AppflyteEventTypes';
import getAppflyteFunctionsData from '../../../utils/ApiFunctions/AppflyteFunctions';
import getAppflyteEnginesData from '../../../utils/ApiFunctions/AppflyteEngines';

import { setAppflyteEngineState, setAppflyteFunctionState, setAppflyteEventTypesState } from "../../../Redux/slice/dataSlice";
import { setAppflyteEngineAdded, setAppflyteFunctionAdded, setAppflyteEventTypesAdded, setAgentApiTokenAdded } from "../../../Redux/slice/newDataSlice";
import LoadBar from '../../../utils/LoadBar';
import AgentApiToken from '../../../Api/Services/AppflyteBackend/AgentApiToken';
import { tostAlert } from '../../../utils/AlertToast';
import { apiErrorHandler } from '../../../utils/ApiErrorHandler';

function CreateKey() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [currentOwner, setCurrentOwner] = useState("you");

    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [appflyteEventTypes, setAppflyteEventTypes] = useState([])
    const [appflyteFunctions, setAppflyteFunctions] = useState([])
    const [appflyteEngines, setAppflyteEngines] = useState([])

    const [tokenName, setTokenName] = useState('');
    const [permissionType, setPermissionType] = useState('all')
    const [tokenPermissions, setTokenPermissions] = useState([]);
    const [tokenExpireTime, setTokenExpireTime] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    // Redux States        
    const selected_space = useSelector(state => state.current_selected_data.selected_space)
    const selected_project = useSelector(state => state.current_selected_data.selected_project)
    const appflyte_engines = useSelector(state => state.all_data.appflyte_engines);
    const appflyte_functions = useSelector(state => state.all_data.appflyte_functions);
    const appflyte_event_types = useSelector(state => state.all_data.appflyte_event_types);
    const appflyte_engine_added = useSelector(state => state.data_added.appflyte_engine_added);
    const appflyte_functions_added = useSelector(state => state.data_added.appflyte_functions_added);
    const appflyte_event_types_added = useSelector(state => state.data_added.appflyte_event_types_added);

    useEffect(() => {
        getAllData()
    }, [selected_project, selected_space])

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.tokenName && tokenName.trim()) { delete errors?.tokenName }
        if (formErrors?.tokenExpireTime && tokenExpireTime) { delete errors?.tokenExpireTime }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [tokenName, tokenExpireTime, formErrors]);

    const getAllEventTypes = async () => {
        if (appflyte_event_types?.length > 0 && appflyte_event_types_added === false) {
            return appflyte_event_types
        }
        const response = await getAppflyteEventTypesData()
        dispatch(setAppflyteEventTypesState(response))
        dispatch(setAppflyteEventTypesAdded(false))
        return response
    }

    const getAllFunctions = async () => {
        if (appflyte_functions?.length > 0 && appflyte_functions_added === false) {
            return appflyte_functions
        }
        const response = await getAppflyteFunctionsData()
        dispatch(setAppflyteFunctionState(response))
        dispatch(setAppflyteFunctionAdded(false))
        return response
    }

    const getAllEngines = async () => {
        if (appflyte_engines?.length > 0 && appflyte_engine_added === false) {
            return appflyte_engines
        }
        const response = await getAppflyteEnginesData()
        dispatch(setAppflyteEngineState(response))
        dispatch(setAppflyteEngineAdded(false))
        return response
    }

    const getAllData = async () => {
        setLoading(true)
        try {
            const [eventTypesResponse, functionsResponse, engineResponse] = await Promise.all([
                getAllEventTypes(),
                getAllFunctions(),
                getAllEngines()
            ])

            if (eventTypesResponse && functionsResponse && engineResponse) {
                setAppflyteEventTypes(eventTypesResponse)
                setAppflyteFunctions(functionsResponse)
                setAppflyteEngines(engineResponse)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleCreateNewKey = async () => {
        setLoading(true)
        try {
            const errors = {};
            if (!tokenName.trim()) { errors.tokenName = 'Token name is required' }
            if (!tokenExpireTime) { errors.tokenExpireTime = 'Token Expiry Date is required' }
            if (Object.keys(errors).length === 0) {
                const userName = await getUserName();
                const reqObj = {
                    workspace_id: selected_space?.payload?.__auto_id__,
                    project_id: selected_project?.payload?.__auto_id__,
                    engine_type: selected_project?.payload?.configuration?.engine_name,
                    name: tokenName,
                    owned_by: userName,
                    permissions: permissionType,
                    exp: tokenExpireTime,
                    resource_type_data: tokenPermissions,
                    token_type: "user_token"
                }
                const response = await AgentApiToken.createAgentApiToken(JSON.stringify(reqObj))
                if (response.status === 200) {
                    dispatch(setAgentApiTokenAdded(true))
                    tostAlert('Token Created Successfully', 'success');
                    navigate('/settings/api-keys')
                    return
                }
                tostAlert('Token Creation Failed', 'error')
            }
            else {
                setFormErrors(errors);
            }
        } catch (error) {
            apiErrorHandler(error)
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit"
            onClick={() => navigate('/settings/api-keys')}
            sx={breadCrumbsStyles.linkHeader}>
            <Typography>API Keys</Typography>
        </Link>,
        <Typography key="1" sx={breadCrumbsStyles.nonlinkHeader}>New Key</Typography>,
    ];

    return (
        <Box >
            {(loading || dataLoading) && (<LoadBar />)}
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                <Stack spacing={2} sx={{ marginLeft: '0' }}>
                    <Breadcrumbs
                        separator={<NavigateNextRounded fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>

                <Box display={'flex'} alignItems={'center'}>
                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '100px' }}
                        disabled={loading || dataLoading}
                        onClick={() => handleCreateNewKey()}
                    >
                        <Typography sx={fontStyles.btnText}>SAVE</Typography>
                    </Button>
                    <Button
                        sx={{ ...buttonStyles.secondaryBtn, width: '100px', marginLeft: '10px' }}
                        onClick={() => navigate('/settings/api-keys')}
                    >
                        <Typography sx={fontStyles.btnText}>CANCEL</Typography>
                    </Button>
                </Box>
            </Box>

            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>Select who can access AI Agent</Typography>

            <Box marginTop={"10px"}>
                <FormControl>
                    <FormLabel style={{ color: "black" }}>
                        <Typography sx={{ ...fontStyles.mediumText, marginTop: '10px' }}>
                            Ownership
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={currentOwner}
                        onChange={(e) => setCurrentOwner(e.target.value)}
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <FormControlLabel value="you" control={<Radio sx={mainStyles.radionBtnColor} />} label="You" />
                        <FormControlLabel value="service_account" control={<Radio sx={mainStyles.radionBtnColor} />} label="Service Account" />
                    </RadioGroup>
                </FormControl>
            </Box>

            <Box marginTop={'15px'}>
                {currentOwner === "you" &&
                    <OwnPermissions
                        setDataLoading={setDataLoading}
                        loading={loading}
                        dataLoading={dataLoading}
                        setTokenName={setTokenName}
                        setPermissionType={setPermissionType}
                        setTokenPermissions={setTokenPermissions}
                        setTokenExpireTime={setTokenExpireTime}
                        tokenName={tokenName}
                        appflyteEventTypes={appflyteEventTypes}
                        appflyteFunctions={appflyteFunctions}
                        appflyteEngines={appflyteEngines}
                        formErrors={formErrors}
                    />
                }
                {currentOwner === "service_account" && <ServiceAccountPermission />}
            </Box>
        </Box>
    )
}

export default CreateKey;