import AxiosFileObj from "../../Configurations/axios-file-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails";
import axios from "axios";

class dpodappFlyteApi {

    constructor() {
        this.accountId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscriberId = await fetchSubscriberId()
        this.subscriptionId = await fetchSubscriptionId()
    }

    createSourceFolders = async (reqBody) => {
        await this.initialize();
        return AxiosFileObj.post(`/api/${this.accountId}/dpodapp/dpod-coach/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/folder`, reqBody)
    }

    getSourceFolders = async () => {
        await this.initialize();
        return AxiosFileObj.get(`/api/${this.accountId}/dpodapp/dpod-coach/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/folder`)
    }

    getSourceFoldersById = async (folderId) => {
        await this.initialize();
        return AxiosFileObj.get(`/api/${this.accountId}/dpodapp/dpod-coach/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/folder?folderId=${folderId}`)
    }

    getPredignedURL = async (reqBody) => {
        await this.initialize();
        return AxiosFileObj.post(`/api/${this.accountId}/saas/account/presigned-url`, reqBody)
    }

    getUploadedFileUrls = async (fileIds) => {
        await this.initialize();
        return AxiosFileObj.get(`/api/${this.accountId}/saas/account/presigned-url?fileIds=${fileIds}`)
    }

    uploadFile = async (url, reqBody) => {
        return axios.post(url, reqBody)
    }

    createFile = async (reqBody) => {
        await this.initialize();
        return AxiosFileObj.post(`api/${this.accountId}/dpodapp/dpod-coach/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/file`, reqBody)
    }

}

const FilesApi = new dpodappFlyteApi();
export default FilesApi;
