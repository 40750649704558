import { AxiosObj } from "../../Configurations/axios-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"
import UpdateHeaders from "../../Configurations/UpdateHeaders";

class dpodappFlyteApi {

    constructor() {
        this.accountId = null;
        this.subscriber_id = null;
        this.subscription_id = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscriber_id = await fetchSubscriberId();
        this.subscription_id = await fetchSubscriptionId()
    }

    createAgentApiToken = async (reqObj) => {
        await this.initialize();
        return AxiosObj.post(`/api/ameya/subscriber/${this.subscriber_id}/subscription/${this.subscription_id}/create_agent_api_token`, reqObj)
    }

    getAllAgentTokens = async (last_evaluated_key) => {
        await this.initialize();
        return AxiosObj.get(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/agent_api_tokens?filters=null&last_evaluated_key=${last_evaluated_key}&page_size=50&include_detail=true`)
    }

    deleteToken = async (item_Id) => {
        await this.initialize();
        return AxiosObj.delete(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/agent_api_token/${item_Id}`)
    }

}

const AgentApiToken = new dpodappFlyteApi();
export default AgentApiToken;


