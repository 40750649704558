import spaceIcon from "../images/icons/spaces.svg";
import projectIcon from "../images/icons/projects.svg";
import insightIcon from "../images/icons/insight.svg";
import sourcesIcon from "../images/icons/sources.svg";
import settingsIcon from "../images/icons/settings.svg";
import launchIcon from "../images/icons/launch.svg";
import filesIcon from "../images/icons/files.svg";
import playgroundIcon from "../images/icons/playground.svg";
import apiIcon from "../images/icons/api.svg";
import deleteIcon from "../images/icons/delete.svg";
import editIcon from "../images/icons/edit.svg";
import trainedIcon from "../images/icons/trained.svg";
import trainingIcon from "../images/icons/training.svg";
import pdfIcon from "../images/icons/pdf.svg";
import docIcon from "../images/icons/doc.svg";
import txtIcon from "../images/icons/txt.svg";
import pptIcon from "../images/icons/ppt.svg";
import resultCorrectIcon from "../images/icons/result_correct.svg";
import resultErrorIcon from "../images/icons/result_error.svg";
import genralSettingsIcon from "../images/icons/settings-2.svg";
import llmIcon from "../images/icons/llm.svg";
import keyIcon from "../images/icons/key.svg";
import thumbupIcon from "../images/icons/thumb_up.svg";
import thumbdownIcon from "../images/icons/thumb_down.svg";
import aiAnalystIcon from "../images/icons/ai_analyst_icon.svg";
import aiQmsIcon from "../images/icons/ai_qms_icon.svg";
import docSearchIcon from "../images/icons/doc_search_icon.svg";
import genrateDashboardIcon from "../images/icons/genrate_dashboard_icon.svg";
import xlsxIcon from "../images/icons/xlsxIcon.svg";
import csvIcon from "../images/icons/csvIcon.svg";
import realmIcon from "../images/icons/realm.svg";
import filterOpenIcon from "../images/icons/Filter-open.svg";
import filterSelectedIcon from "../images/icons/Filter-selected.svg";


export const IconSvg = {
    spaceIcon,
    projectIcon,
    insightIcon,
    sourcesIcon,
    settingsIcon,
    launchIcon,
    filesIcon,
    playgroundIcon,
    apiIcon,
    deleteIcon,
    editIcon,
    trainedIcon,
    trainingIcon,
    pdfIcon,
    docIcon,
    resultCorrectIcon,
    resultErrorIcon,
    genralSettingsIcon,
    llmIcon,
    keyIcon,
    txtIcon,
    pptIcon,
    thumbupIcon,
    thumbdownIcon,
    aiAnalystIcon,
    aiQmsIcon,
    docSearchIcon,
    genrateDashboardIcon,
    xlsxIcon,
    csvIcon,
    realmIcon,
    filterOpenIcon,
    filterSelectedIcon
};