import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Styles } from './Styles';

import SubSidebar from './SubSidebar/SubSidebar';
import GenralSettings from './GenralSettings/GenralSettings';
import APiKeys from './APIKeys/APiKeys';
import LLMSettings from './LLMSettings/LLMSettings';
import CreateKey from './APIKeys/CreateKey';
import RealmSettings from './RealmSettings/RealmSettings';

function SettingsHome() {

    const navigate = useNavigate();
    const location = useLocation()

    const handleMenuChange = (selectedPage) => {
        navigate(`/settings/${selectedPage.toLowerCase()}`);
    };

    useEffect(() => {
        if (location.pathname === '/settings') {
            navigate('/settings/genral');
        }
    }, [location.pathname, navigate]);

    return (
        <Box sx={Styles.mainContainer}>
            <Box sx={Styles.sidebar}>
                <SubSidebar handleMenuChange={handleMenuChange} />
            </Box>
            <main style={Styles.componentContainer}>
                <Routes>
                    <Route path="genral" element={<GenralSettings />} />
                    <Route path="llm" element={<LLMSettings />} />
                    <Route path="api-keys" element={<APiKeys />} />
                    <Route path="api-keys/create-api-keys" element={<CreateKey />} />
                    <Route path="realm" element={<RealmSettings />} />
                </Routes>
            </main>
        </Box>
    )
}

export default SettingsHome;