import FilesApi from "../../Api/Services/AppflyteBackend/FileServiceApi";

const ProjectParentFolders = async () => {


    const getAllProjectParentFolders = async () => {
        const tempArr = []
        try {
            const response = await FilesApi.getSourceFolders();
            if (response.data) {
                const responseData = response?.data?.folders ?? [];
                tempArr.push(...responseData)
            }
            return tempArr;
        }
        catch (error) {
            console.error(error)
        }
    }

    return getAllProjectParentFolders();
};

export default ProjectParentFolders;