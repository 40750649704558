import { configureStore } from "@reduxjs/toolkit";

import allDataReducer from "../slice/dataSlice";
import newDataReducer from "../slice/newDataSlice";
import selectedCurrentDataReducer from "../slice/currentSelectedSlice";

const store = configureStore(
    {
        reducer: {
            all_data: allDataReducer,
            data_added: newDataReducer,
            current_selected_data: selectedCurrentDataReducer,
        },
    }
)

export default store;