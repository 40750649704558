import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Styles } from './Styles';
import SubSidebar from './SubSidebar/SubSidebar';

import AuditToolPlaygroundHistory from './AuditTool/AuditToolPlaygroundHistory';
import AuditToolPlayground from './AuditTool/AuditToolPlayground';
import AuditToolApi from './AuditTool/AuditToolApi';

import DocSearchPlayground from './DocumentSearch/DocSearchPlayground';
import DocSearchApi from './DocumentSearch/DocSearchApi';

import LLMDocSearchPlayground from './LLMDocSearch/LLMDocSearchPlayground';
import LLMDocSearchApi from './LLMDocSearch/LLMDocSearchApi';


function LaunchHome() {

    const navigate = useNavigate();
    const location = useLocation();
    const selected_project = useSelector(state => state.current_selected_data.selected_project)

    useEffect(() => {
        const selected_engine_name = selected_project?.payload?.configuration?.engine_name
        if (location.pathname === '/launch') {

            if (selected_engine_name === "qms_audit_tool") {
                navigate('/launch/qms-audit-tool/playground');
            }

            if (selected_engine_name === "doc_search") {
                navigate('/launch/doc-search/playground');
            }

            if (selected_engine_name === "llm_doc_search") {
                navigate('/launch/llm-doc-search/playground');
            }

        }
    }, [location.pathname, navigate, selected_project]);

    const handleMenuChange = (engine, path) => {
        if (engine && path) {
            navigate(`/launch/${engine?.toLowerCase()?.replace(/_/g, '-')}/${path?.toLowerCase()?.replace(/_/g, '-')}`);
        }
    };

    return (
        <Box sx={Styles.mainContainer}>

            <Box sx={Styles.sidebar}>
                <SubSidebar handleMenuChange={handleMenuChange} />
            </Box>

            <main style={Styles.componentContainer}>
                <Routes>
                    <Route path="qms-audit-tool/playground" element={<AuditToolPlaygroundHistory />} />
                    <Route path="qms-audit-tool/playground/test" element={<AuditToolPlayground />} />
                    <Route path="qms-audit-tool/api" element={<AuditToolApi />} />

                    <Route path="doc-search/playground" element={<DocSearchPlayground />} />
                    <Route path="doc-search/api" element={<DocSearchApi />} />

                    <Route path="llm-doc-search/playground" element={<LLMDocSearchPlayground />} />
                    <Route path="llm-doc-search/api" element={<LLMDocSearchApi />} />
                </Routes>
            </main>

        </Box>
    )
}

export default LaunchHome;