import React, { useState, useEffect, useRef } from 'react';

import {
    Box, Typography, SwipeableDrawer, IconButton, Avatar, Button, CircularProgress,
    TableCell, Paper, TableContainer, TableHead, TableRow, Table, TableBody, MenuItem, Select, Pagination
} from '@mui/material';
import { ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';

import { Styles } from './Styles';
import { mainStyles } from '../../../../styles/styles';
import { fontStyles } from '../../../../styles/fontStyles';
import { tableStyles } from '../../../../styles/tableStyles';

import { getUserInitials } from '../../../../utils/GetAccountDetails';

function ChatHandler({ chatdrawer, toggleDrawer, qmsChatData, handleQmsChat, chatMessageLoading,
    testResponse, userFeedback, handleUserThumbsFeedback, handleUserChatFeedback }) {

    const [processDatarowsPerPage, setProcessDataRowsPerPage] = useState('5');
    const [processDataPage, setProcessDataPage] = useState(1);
    const [totalProcessDataPages, setTotalProcessDataPages] = useState(1);
    const [loadingDots, setLoadingDots] = useState('');
    const chatWindowRef = useRef(null);

    useEffect(() => {
        setTotalProcessDataPages(Math.ceil(testResponse?.test_data?.length / parseInt(processDatarowsPerPage)));
    }, [testResponse?.test_data, processDatarowsPerPage]);

    let PaginatedProcessData = [];
    const handleProcessDataPage = (event, newPage) => { setProcessDataPage(newPage) }

    const handleProcessDataRowsPerPageChange = event => {
        setProcessDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(testResponse?.test_data?.length / parseInt(newRowsPerPage));
        setProcessDataRowsPerPage(newRowsPerPage);
        setProcessDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalProcessDataPages(newTotalPages);
    };
    if (testResponse?.test_data?.length > 0) {
        const startIndex = (processDataPage - 1) * parseInt(processDatarowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(processDatarowsPerPage), testResponse?.test_data?.length);
        PaginatedProcessData = testResponse?.test_data?.slice(startIndex, endIndex);
    }

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [qmsChatData, testResponse]);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingDots(prevDots => (prevDots.length < 6 ? prevDots + '.' : ''));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const getRenderableItems = (data) => {
        let hasShownUnprocessed = false;

        return data?.filter(msg => {

            if (!msg?.isProcessed && !hasShownUnprocessed) {
                hasShownUnprocessed = true;
                return true;
            }

            if (msg?.isProcessed) {
                return true;
            }

            return false;
        });
    };


    const renderableItems = qmsChatData?.length > 0 ? getRenderableItems(qmsChatData) : [];

    return (
        <SwipeableDrawer
            anchor='right'
            open={chatdrawer.state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
                height: '100vh',
            }}
        >
            <Box sx={mainStyles.sideDrawerContainer}>

                <Typography style={fontStyles.drawerHeading}>Train AI QMS Agent</Typography>

                {!chatMessageLoading && renderableItems.length === 0 && (
                    <Box sx={Styles.chatBoxLoadingContainer}>
                        No Preview Available
                    </Box>
                )}

                {renderableItems?.length === 0 && chatMessageLoading ?
                    (<Box sx={Styles.chatBoxLoadingContainer}>
                        <CircularProgress />
                    </Box>) :
                    (<Box sx={Styles.chatBoxOuterContainer}>
                        <Box ref={chatWindowRef} sx={Styles.chatBoxInnerContainer}>

                            {renderableItems?.map((msg, index) => {

                                return (
                                    <Box key={index}>
                                        {<Box display={'flex'} width={'100%'}>

                                            {msg?.sender === 'user' &&
                                                <Box width={'20px'} marginTop={'5px'}>
                                                    <Avatar sx={{ bgcolor: '#000000', height: '32px', width: '32px' }}>
                                                        <Typography sx={fontStyles.mediumText}>{getUserInitials()}</Typography>
                                                    </Avatar>
                                                </Box>}

                                            {msg?.sender === 'system' &&
                                                <Box width={'20px'} marginTop={'5px'}>
                                                    <Box sx={mainStyles.ameya_icon}>
                                                    </Box>
                                                </Box>
                                            }

                                            <Box
                                                sx={Styles.chatBoxMessageContainer}
                                            >
                                                <Typography
                                                    sx={{
                                                        ...fontStyles.smallText,
                                                        ...Styles.chatMessages
                                                    }}
                                                >
                                                    {msg.chat}
                                                </Typography>
                                            </Box>
                                        </Box>}

                                        {msg?.feedBackOptions?.length > 0 && msg?.sender === 'system' && !msg?.isProcessed &&
                                            < Box width={'100%'}>
                                                <Box sx={Styles.chatResponseContainer}>
                                                    {msg?.feedBackOptions?.map((item, index) => (
                                                        <Button
                                                            key={index}
                                                            sx={{ ...Styles.chatResponsebtn }}
                                                            onClick={() => handleQmsChat(item, msg.questionId)}
                                                        >
                                                            <Typography sx={{ ...fontStyles.smallText, textTransform: 'capitalize' }}>{item}</Typography>
                                                        </Button>
                                                    ))}
                                                </Box>
                                            </Box>
                                        }
                                    </Box>)
                            })}

                            {userFeedback &&
                                <Box width={'100%'}>
                                    {testResponse?.test_data?.length > 0 &&
                                        <Box padding={'10px'}>
                                            <Paper>
                                                < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                                                    <Table stickyHeader sx={{ height: testResponse?.test_data?.length <= 0 ? '400px' : `${testResponse?.test_data?.length}px` }}>
                                                        <TableHead sx={tableStyles.thead}>
                                                            <TableRow>
                                                                <TableCell sx={{ ...tableStyles.thcell, width: '40%' }}>Specification</TableCell>
                                                                <TableCell sx={{ ...tableStyles.thcell, width: '60%' }}>Report Value</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody sx={tableStyles.tbody}>
                                                            {testResponse?.test_data?.length > 0 && PaginatedProcessData?.map((item, index) => (
                                                                <TableRow sx={tableStyles.trow} key={index}>
                                                                    <TableCell sx={{ ...tableStyles.tdcell }}>{item?.test_name}</TableCell>
                                                                    <TableCell sx={{ ...tableStyles.tdcell }}>{item?.result_value}&nbsp;{item?.uom}</TableCell>
                                                                </TableRow>))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                                {testResponse?.test_data?.length > 0 &&
                                                    (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                                                        <Box display={'flex'} alignItems={'center'}>
                                                            <Box marginLeft={'20px'}>
                                                                <Select
                                                                    value={processDatarowsPerPage}
                                                                    onChange={handleProcessDataRowsPerPageChange}
                                                                    sx={{ width: '110px', height: '35px' }}
                                                                >
                                                                    <MenuItem value={'5'}>5 rows</MenuItem>
                                                                    <MenuItem value={'10'}>10 rows</MenuItem>
                                                                    <MenuItem value={'15'}>15 rows</MenuItem>
                                                                    <MenuItem value={'20'}>20 rows</MenuItem>
                                                                </Select>
                                                            </Box>
                                                        </Box>
                                                        <Pagination
                                                            count={totalProcessDataPages}
                                                            page={processDataPage}
                                                            onChange={handleProcessDataPage}
                                                            color="standard"
                                                        />
                                                    </Box>)
                                                }
                                            </Paper>
                                        </Box>}

                                    {testResponse?.feedback_thumbs_options?.length > 0 &&
                                        <Box sx={Styles.chatFeedBackContainer}>
                                            {testResponse?.feedback_thumbs_options?.map((item, index) => (
                                                <Box key={index} sx={{ marginLeft: '5px' }}>
                                                    <IconButton
                                                        sx={{
                                                            backgroundColor: '#0B51C5',
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: 'white',
                                                                color: '#0B51C5',
                                                            },
                                                            '&:active': {
                                                                backgroundColor: 'white',
                                                                color: '#0B51C5',
                                                            },
                                                        }}
                                                        disabled={chatMessageLoading}
                                                        onClick={() => handleUserThumbsFeedback(item)}
                                                    >
                                                        {item === "thumbs_up" ? <ThumbUpAlt /> : <ThumbDownAlt />}
                                                    </IconButton>
                                                </Box>
                                            ))}
                                        </Box>
                                    }

                                    {testResponse?.feedback_chat_options?.length > 0 &&
                                        <Box sx={Styles.chatFeedBackContainer}>
                                            {testResponse?.feedback_chat_options?.map((item, index) => (
                                                < Box display={'flex'} key={index}>
                                                    <Button
                                                        sx={{ ...Styles.chatFeedbackbtn, marginLeft: '10px' }}
                                                        disabled={chatMessageLoading}
                                                        onClick={() => handleUserChatFeedback(item)}
                                                    >
                                                        <Typography sx={{ ...fontStyles.smallText }}>{item?.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</Typography>
                                                    </Button>
                                                </Box>
                                            ))}
                                        </Box>
                                    }
                                </Box>
                            }

                            {chatMessageLoading &&
                                <Box
                                    width={'100%'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    marginBottom={'20px'}
                                    paddingBottom={'20px'}
                                    color={'#0B51C5'}
                                    fontSize={'20px'}
                                >
                                    {loadingDots}
                                </Box>
                            }

                        </Box>
                    </Box>)
                }
            </Box>
        </SwipeableDrawer >
    )
}

export default ChatHandler;

