import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Styles } from './Styles';
import { Typography } from '@mui/material';

import { IconSvg } from '../../../utils/globalIcons';
import { ReactSVG } from "react-svg";

function SubSidebar({ handleMenuChange }) {
    const location = useLocation()
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);

    const menuItems = [
        { icon: IconSvg.genralSettingsIcon, label: 'Genral', path: 'genral' },
        { icon: IconSvg.llmIcon, label: 'LLM', path: 'llm' },
        { icon: IconSvg.keyIcon, label: 'API Keys', path: 'api-keys' },
        { icon: IconSvg.realmIcon, label: 'Realm', path: 'realm' }
    ];

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const currentPath = pathSegments[2];
        const activeIndex = menuItems.findIndex((item) => item.path === currentPath);
        setSelectedMenuItem(activeIndex !== -1 ? activeIndex : 0);
    }, [location.pathname]);

    const handleMenuItemClick = (index, path) => {
        setSelectedMenuItem(index);
        handleMenuChange(path)
    };

    return (
        <nav style={Styles.sidebar}>
            <ul className="menu">
                {menuItems.map((item, index) => (
                    <li key={index}
                        className={`datasource-menu-item ${selectedMenuItem === index ? 'active' : ''}`}
                        onClick={() => handleMenuItemClick(index, item.path)}
                    >
                        <span className="datasource-icon">
                            <ReactSVG
                                src={item.icon}
                                className={`sidebar_icon ${selectedMenuItem === index ? 'active' : ''}`}
                            />
                        </span>
                        <Typography className="datasource-label" variant="caption">{item.label}</Typography>
                    </li>
                ))}

            </ul>
        </nav>
    )
}

export default SubSidebar;