import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from "jwt-decode";

import './styles/App.css';
import Signin from './Auth/Signin';
import Authorized from './Auth/Authorized';
import { routesConfig } from './Routes/Routes';
import ProtectedRoutes from './Routes/ProtectedRoutes';

import { getUserRole } from "./utils/getUserRole"
import { mainStyles } from './styles/styles';
import Sidebar from './pages/Sidebar/Sidebar';
import Navbar from './pages/Navbar/Navbar';
import RefreshTokenApi from './Api/Services/AppflyteBackend/RefreshTokenApi';

function App() {
  const navigate = useNavigate()
  const location = useLocation()

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    handleAuthentication()
    //eslint-disable-next-line
  }, [navigate])

  const handleAuthentication = async () => {
    const jwtIdToken = JSON.parse(localStorage.getItem('dpod-token'))
    if (location.pathname !== "/authorized") {
      if (jwtIdToken) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
        localStorage.clear()
        navigate('/login');
      }
    }
  }

  // Handle Refresh Token
  const refreshAccessToken = async (refreshtoken) => {
    try {
      const response = await RefreshTokenApi.getRefreshToken(refreshtoken)
      const jwtToken = await response.json();
      if (jwtToken) {
        localStorage.setItem('dpod-token', JSON.stringify(jwtToken.token));
        localStorage.setItem('refresh-token', JSON.stringify(jwtToken.refresh_token));
      } else {
        navigate("/login")
        localStorage.clear();
      }
    }
    catch (error) {
      navigate("/login")
      localStorage.clear();
      console.log(error)
    }

  }

  const isTokenAboutToExpire = (jwtToken) => {
    const token = jwtDecode(jwtToken);
    const tokenExpireTime = token.exp
    const currentTime = Math.floor(Date.now() / 1000);
    const timeDifferenceInSeconds = Math.abs(tokenExpireTime - currentTime);
    return timeDifferenceInSeconds <= 600 ? true : false
  }

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const jwtToken = JSON.parse(localStorage.getItem('dpod-token'))
        const refreshToken = JSON.parse(localStorage.getItem('refresh-token'))
        if (location.pathname !== "/authorized") {
          if (jwtToken && refreshToken) {
            const is_expiring = isTokenAboutToExpire(jwtToken)
            if (is_expiring) {
              refreshAccessToken(refreshToken);
            }
          }
        }
      }
      catch (err) {
        console.log("Invalid Token", err);
        navigate("/login")
        localStorage.clear();
      }
    }, 5000);

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [])

  return (

    <div className="App" style={mainStyles.mainContainer}>
      <ToastContainer position="top-center" autoClose={3500} hideProgressBar newestOnTop={false} closeOnClick
        rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='colored' />

      {!isAuthenticated ?
        (<Routes>
          <Route path='/login' element={<Signin />}></Route>
          <Route path='/authorized' element={<Authorized />}></Route>
        </Routes>)
        :
        (<>

          <div style={mainStyles.sidebarContainer}>
            <Sidebar />
          </div>

          <div style={mainStyles.navMainConatainer}>

            <div style={mainStyles.navContainer}>
              <Navbar />
            </div>

            <main style={mainStyles.mainComponentContainer}>
              <Routes>
                <Route element={<ProtectedRoutes />}>
                  {routesConfig.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                  ))}
                </Route>
              </Routes>
            </main>
          </div>
        </>)
      }
    </div>
  );
}

export default App;
