// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete_icon svg {
    color: #C30E2E;
    transition: color 0.3s ease;
    stroke: #C30E2E;
    transition: stroke 0.3s ease;
    cursor: pointer;
}

.delete_icon:hover svg {
    color: #6b0316;
    stroke: #6b0316;
}

.edit_icon svg {
    color: #000000;
    transition: color 0.3s ease;
    stroke: #000000;
    transition: stroke 0.3s ease;
    cursor: pointer;
}

.edit_icon:hover svg {
    color: #a45f5f;
    stroke: #a45f5f;
}`, "",{"version":3,"sources":["webpack://./src/containers/Settings/APIKeys/Styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;IACf,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".delete_icon svg {\n    color: #C30E2E;\n    transition: color 0.3s ease;\n    stroke: #C30E2E;\n    transition: stroke 0.3s ease;\n    cursor: pointer;\n}\n\n.delete_icon:hover svg {\n    color: #6b0316;\n    stroke: #6b0316;\n}\n\n.edit_icon svg {\n    color: #000000;\n    transition: color 0.3s ease;\n    stroke: #000000;\n    transition: stroke 0.3s ease;\n    cursor: pointer;\n}\n\n.edit_icon:hover svg {\n    color: #a45f5f;\n    stroke: #a45f5f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
