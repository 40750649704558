export const Styles = {
    mainContainer: {
        width: '100%',
        height: '100vh'
    },
    fileUploadContainer: {
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#F3F5F7',
        borderRadius: '20px',
    }
    ,

    // File Upload:
    uploadLoadingBox: {
        position: 'fixed',
        bottom: '35px',
        right: '150px',
        zIndex: 2100,
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        minHeight: '120px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white'
    },
    uploadingHeaderBox: {
        display: 'flex',
        height: '40px',
        paddingLeft: '15px',
        paddingRight: '15px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'red'
    },
    uploadingText: {
        fontSize: 15,
        fontWeight: 400,
        color: '#FFFFFF',
    },
    closeIcon: {
        height: '20px',
        width: '20px',
        color: '#FFFFFF',
        cursor: 'pointer'
    },
    tickIcon: {
        height: '18px',
        width: '18px',
        marginRight: '15px'
    },
    failedIcon: {
        height: '18px',
        width: '18px',
        marginRight: '15px',
        color: 'red'
    },
    dropZoneFont: {
        fontSize: 14,
        fontWeight: 400,
        color: '#404040'
    },
}