import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import moment from 'moment';

import { Box, Button, TextField, Typography, Grid2 as Grid, Card, CardContent } from '@mui/material';
import { DashboardOutlined, DescriptionOutlined, DocumentScannerOutlined, MessageOutlined } from '@mui/icons-material';

import { Styles } from './Styles';
import { fontStyles } from '../../styles/fontStyles';
import { buttonStyles } from '../../styles/buttonStyles';
import { searchBarStyles } from '../../styles/searchBarStyles';

import LoadBar from '../../utils/LoadBar';
import { tostAlert } from '../../utils/AlertToast';
import { AlertMessages } from '../../utils/AlertMessages';
import { IconSvg } from '../../utils/globalIcons';
import { apiErrorHandler } from '../../utils/ApiErrorHandler';

import { setProjectAdded, setAppflyteEngineAdded } from "../../Redux/slice/newDataSlice";
import { setAppflyteEngineState } from "../../Redux/slice/dataSlice";
import aitoolconfig from './aitoolconfig.json'
import ProjectsApi from '../../Api/Services/AppflyteBackend/ProjectsApi';
import getAppflyteEnginesData from '../../utils/ApiFunctions/AppflyteEngines';
import { getUserName } from '../../utils/GetAccountDetails';
import { mainStyles } from '../../styles/styles';

function CreateProject() {
    const { space_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [appflyteEngines, setAppflyteEngines] = useState([]);
    const [loading, setLoading] = useState(false)

    const projectDetails = {
        projectName: '',
        projectDescription: '',
        projectAiEngine: '',
        projectEngineConfig: {}
    }
    const [projectData, setProjectData] = useState(projectDetails);
    const [formErrors, setFormErrors] = useState({});

    // Redux States
    const current_space = location?.state?.space_name || null
    const all_engines = useSelector(state => state.all_data.appflyte_engines);
    const engine_added = useSelector(state => state.data_added.appflyte_engine_added);

    useEffect(() => {
        getAllAppflyteEngines();
    }, [engine_added])

    const getAllAppflyteEngines = async () => {
        setLoading(true)
        let response = []
        if (all_engines?.length > 0 && engine_added === false) {
            response = all_engines
        } else {
            response = await getAppflyteEnginesData()
            dispatch(setAppflyteEngineState(response))
            dispatch(setAppflyteEngineAdded(false))
        }
        setAppflyteEngines(response)
        setLoading(false)
    }

    useEffect(() => {
        const errors = { ...formErrors };
        if (formErrors?.projectName && projectData?.projectName?.trim()) { delete errors?.projectName }
        if (formErrors?.projectDescription && projectData?.projectDescription?.trim()) { delete errors?.projectDescription }
        if (formErrors?.projectEngineConfig && Object?.keys(projectData.projectEngineConfig).length > 0) { delete errors?.projectEngineConfig }
        if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
    }, [projectData, formErrors]);

    const handleAiEngineSelection = async (selected_engine_id, selected_engine_config) => {

        if (selected_engine_id === projectData?.projectAiEngine) {
            setProjectData({
                ...projectData,
                projectEngineConfig: {},
                projectAiEngine: ''
            })
            return
        }

        const engine_config = {
            "engine_name": selected_engine_config?.engine_name,
            "engine_config": selected_engine_config?.engine_config
        }
        setProjectData({
            ...projectData,
            projectEngineConfig: engine_config,
            projectAiEngine: selected_engine_id
        })
    }

    const handleCreateProject = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const errors = {};
            if (!projectData?.projectName?.trim()) { errors.projectName = 'Project name is required' }
            if (!projectData?.projectDescription?.trim()) { errors.projectDescription = 'Project description is required' }
            if (Object?.keys(projectData.projectEngineConfig).length === 0) { errors.projectEngineConfig = 'Ai Engine is required' }
            if (Object.keys(errors).length === 0) {
                const userName = getUserName()
                const projectObject = {
                    collection_item: {
                        name: projectData.projectName,
                        description: projectData.projectDescription,
                        workspace: [space_id],
                        lookup_id: [projectData.projectAiEngine],
                        configuration: projectData.projectEngineConfig,
                        status: 'active',
                        created_by: userName,
                        created_on: moment().format("DD-MM-YYYY HH:mm:ss"),
                        history: [
                            {
                                updated_by: userName,
                                status: "active",
                                updated_date: moment().format("DD-MM-YYYY HH:mm:ss")
                            }
                        ],
                    }
                }
                const response = await ProjectsApi.createProject(JSON.stringify(projectObject));
                if (response.status === 200) {
                    setProjectData(projectDetails)
                    dispatch(setProjectAdded(true))
                    const message = await AlertMessages('create', 'project');
                    navigate(`/spaces/${current_space}/projects`)
                    tostAlert(message, 'success')
                } else {
                    const message = await AlertMessages('error', 'project');
                    tostAlert(message, 'error')
                }
            }
            else {
                setFormErrors(errors);
            }
        } catch (error) {
            apiErrorHandler(error);
            console.error("Error creating workspace:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleCancel = () => {
        navigate(`/spaces/${current_space}/projects`)
    }


    return (
        <div style={Styles.mainContainer}>

            {loading && (<LoadBar />)}
            <Box padding={'20px'}>

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                    <Typography sx={fontStyles.primaryText}>Create Project</Typography>

                    <Box display={'flex'} alignItems={'center'}>

                        <Button
                            sx={{ ...buttonStyles.primaryBtn, width: '110px' }}
                            disabled={loading}
                            onClick={(e) => handleCreateProject(e)}
                        >
                            <Typography sx={fontStyles.btnText}>CREATE</Typography>
                        </Button>

                        <Button
                            sx={{ ...buttonStyles.secondaryBtn, width: '110px', marginLeft: '10px' }}
                            onClick={handleCancel}>
                            <Typography sx={fontStyles.btnText}>CANCEL</Typography>
                        </Button>

                    </Box>

                </Box>

                <Box marginTop={'20px'}>
                    <Box>
                        <TextField
                            id="app-name"
                            variant="outlined"
                            placeholder='App Name'
                            size='small'
                            sx={{ ...searchBarStyles.searchBar, width: '40%' }}
                            value={projectData.projectName}
                            onChange={(e) => setProjectData({ ...projectData, projectName: e.target.value })}
                        />
                        {formErrors.projectName && <Typography sx={fontStyles.formErrorText}>{formErrors.projectName}</Typography>}
                    </Box>
                    <Box marginTop={'20px'}>
                        <TextField
                            id="app-description"
                            variant="outlined"
                            placeholder='App Description'
                            size='medium'
                            sx={{ ...searchBarStyles.searchBar, width: '40%' }}
                            value={projectData.projectDescription}
                            onChange={(e) => setProjectData({ ...projectData, projectDescription: e.target.value })}
                        />
                        {formErrors.projectDescription && <Typography sx={fontStyles.formErrorText}>{formErrors.projectDescription}</Typography>}
                    </Box>
                </Box>

                <Box marginTop={'30px'}>

                    <Box display={'flex'} alignItems={'center'}>
                        <Typography sx={fontStyles.primaryText}>Select an AI Engine</Typography>
                        {formErrors.projectEngineConfig && <Typography sx={{ ...fontStyles.formErrorText, marginLeft: '20px' }}>{formErrors.projectEngineConfig}</Typography>}
                    </Box>

                    <Box marginTop={'10px'}>
                        <Grid container spacing={6}>

                            {appflyteEngines?.length > 0 ? appflyteEngines?.map((engine, index) => (
                                <Grid size={{ xs: 6, md: 4 }} key={index}>
                                    <Card sx={{
                                        ...Styles.aiEngineCard,
                                        backgroundColor: projectData.projectAiEngine === engine?.payload?.__auto_id__ ? '#D9D9D9' : '#FFFFFF'
                                    }}
                                        onClick={() => handleAiEngineSelection(engine?.payload?.__auto_id__, engine?.payload?.configuration)}
                                    >
                                        <CardContent sx={{ padding: '0' }}>
                                            <Box sx={Styles.aiEngineCardHeader}>
                                                <ReactSVG src={IconSvg[engine?.payload?.configuration?.engine_icon]} />
                                                <Typography sx={fontStyles.mediumText}>{engine?.payload?.name?.replace(/_/g, ' ')}</Typography>
                                            </Box>
                                            <Box sx={Styles.aiEngineCardBody}>
                                                <Typography sx={fontStyles.smallText}>{engine?.payload?.description}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) :
                                (appflyteEngines?.length === 0 && !loading) && (
                                    <Box sx={mainStyles.noRecord}>
                                        <Typography>No Records To Display</Typography>
                                    </Box>
                                )}


                            {/* <Grid size={{ xs: 6, md: 4 }}  >
                                <Card sx={{
                                    ...Styles.aiEngineCard,
                                    backgroundColor: projectData.projectAiEngine === 'conversational_ai_data_analyst' ? '#D9D9D9' : '#FFFFFF'
                                }}
                                    onClick={() => handleAiEngineSelection("conversational_ai_data_analyst")}
                                >
                                    <CardContent sx={{ padding: '0' }}>
                                        <Box sx={Styles.aiEngineCardHeader}>
                                            <ReactSVG src={IconSvg.aiAnalystIcon} />
                                            <Typography sx={fontStyles.mediumText}>
                                                Conversational  AI Data Analyst
                                            </Typography>
                                        </Box>
                                        <Box sx={Styles.aiEngineCardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                Connect your own data sources, ask
                                                any question and get analyst-grade answers
                                                instantly
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid> */}

                            {/* <Grid size={{ xs: 6, md: 4 }}  >
                                <Card sx={{
                                    ...Styles.aiEngineCard,
                                    backgroundColor: projectData.projectAiEngine === 'generate_dashboards' ? '#D9D9D9' : '#FFFFFF'
                                }}
                                    onClick={() => handleAiEngineSelection("generate_dashboards")}
                                >
                                    <CardContent sx={{ padding: '0' }}>
                                        <Box sx={Styles.aiEngineCardHeader}>
                                            <ReactSVG src={IconSvg.genrateDashboardIcon} />
                                            <Typography sx={fontStyles.mediumText}>
                                                Generate Dashboards
                                            </Typography>
                                        </Box>
                                        <Box sx={Styles.aiEngineCardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                Connect your own data sources, ask
                                                questions and generate personalized
                                                dashboards with rich data visualization
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid> */}

                            {/* <Grid size={{ xs: 6, md: 4 }}  >
                                <Card sx={{
                                    ...Styles.aiEngineCard,
                                    backgroundColor: projectData.projectAiEngine === 'ai_qms' ? '#D9D9D9' : '#FFFFFF'
                                }}
                                    onClick={() => handleAiEngineSelection("ai_qms")}>
                                    <CardContent sx={{ padding: '0' }}>
                                        <Box sx={Styles.aiEngineCardHeader}>
                                            <ReactSVG src={IconSvg.aiQmsIcon} />
                                            <Typography sx={fontStyles.mediumText}>
                                                AI QMS
                                            </Typography>
                                        </Box>
                                        <Box sx={Styles.aiEngineCardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                Analyze PDF documents like test reports
                                                and analyzes deviation against a
                                                specification range
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid> */}

                            {/* <Grid size={{ xs: 6, md: 4 }}  >
                                <Card sx={{
                                    ...Styles.aiEngineCard,
                                    backgroundColor: projectData.projectAiEngine === 'audio_transcription' ? '#D9D9D9' : '#FFFFFF'
                                }}
                                    onClick={() => handleAiEngineSelection("audio_transcription")}>
                                    <CardContent sx={{ padding: '0' }}>
                                        <Box sx={Styles.aiEngineCardHeader}>
                                            <ReactSVG src={IconSvg.docSearchIcon} />
                                            <Typography sx={fontStyles.mediumText}>
                                                Audio Transcription
                                            </Typography>
                                        </Box>
                                        <Box sx={Styles.aiEngineCardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                Convert audio recordings to text,
                                                summarize consulations or meetings,
                                                classify and categorize them to your needs
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid> */}

                            {/* 
                            <Grid size={{ xs: 6, md: 4 }}  >
                                <Card sx={{
                                    ...Styles.aiEngineCard,
                                    backgroundColor: projectData.projectAiEngine === 'document_search' ? '#D9D9D9' : '#FFFFFF'
                                }}
                                    onClick={() => handleAiEngineSelection("document_search")}
                                    onDoubleClick={() => handleAiEngineSelection("document_search")}
                                >
                                    <CardContent sx={{ padding: '0' }}>
                                        <Box sx={Styles.aiEngineCardHeader}>
                                            <ReactSVG src={IconSvg.docSearchIcon} />
                                            <Typography sx={fontStyles.mediumText}>
                                                Advanced Document Search
                                            </Typography>
                                        </Box>
                                        <Box sx={Styles.aiEngineCardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                Convert audio recordings to text,
                                                summarize consulations or meetings,
                                                classify and categorize them to your needs
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid> */}

                            {/* <Grid size={{ xs: 6, md: 4 }}  >
                                <Card sx={{
                                    ...Styles.aiEngineCard,
                                    backgroundColor: projectData.projectAiEngine === 'llmdocsearch' ? '#D9D9D9' : '#FFFFFF'
                                }}
                                    onClick={() => handleAiEngineSelection("llmdocsearch")}
                                    onDoubleClick={() => handleAiEngineSelection("llmdocsearch")}
                                >
                                    <CardContent sx={{ padding: '0' }}>
                                        <Box sx={Styles.aiEngineCardHeader}>
                                            <ReactSVG src={IconSvg.docSearchIcon} />
                                            <Typography sx={fontStyles.mediumText}>
                                                LLM Document Search
                                            </Typography>
                                        </Box>
                                        <Box sx={Styles.aiEngineCardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                Convert audio recordings to text,
                                                summarize consulations or meetings,
                                                classify and categorize them to your needs
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid> */}


                        </Grid>
                    </Box>
                </Box>

            </Box>
        </div>
    )
}

export default CreateProject;