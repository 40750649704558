import axios from "axios";
import agentApiTokenManager from "../../utils/AgentApiToken/getAgentAdminToken";

const requestHandler = async (request) => {

    const agentTokenId = await agentApiTokenManager.getAgentAdminToken();
    request.headers['Authorization'] = `Bearer ${agentTokenId}`;
    request.headers['Content-Type'] = 'application/json';
    request.headers['Accept'] = 'application/json';
    return request
}

const AxiosAuditToolObj = axios.create({
    baseURL: process.env.REACT_APP_QMS_AUDIT_TOOL_URL,
    responseType: "json"
});

const AxiosDocSearchObj = axios.create({
    baseURL: process.env.REACT_APP_DOCUMENT_SEARCH_URL,
    responseType: "json"
});

const AxiosLLMDocSearchObj = axios.create({
    baseURL: process.env.REACT_APP_LLM_DOCUMENT_SEARCH_URL,
    responseType: "json"
});

AxiosAuditToolObj.interceptors.request.use(request => requestHandler(request))
AxiosDocSearchObj.interceptors.request.use(request => requestHandler(request))
AxiosLLMDocSearchObj.interceptors.request.use(request => requestHandler(request))

export { AxiosAuditToolObj, AxiosDocSearchObj, AxiosLLMDocSearchObj };

