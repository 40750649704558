import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Styles } from './Styles'
import { fontStyles } from '../../../styles/fontStyles'
import { searchBarStyles } from '../../../styles/searchBarStyles'
import { buttonStyles } from '../../../styles/buttonStyles';
import { useSelector } from 'react-redux'
import { AlertMessages } from '../../../utils/AlertMessages'
import { tostAlert } from '../../../utils/AlertToast'
import LoadBar from '../../../utils/LoadBar'
import ProjectsApi from '../../../Api/Services/AppflyteBackend/ProjectsApi'

function GenralSettings() {
  const project_info = { projectName: '', projectDescription: '' }
  const [projectdetails, setProjectDetails] = useState(project_info)
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false)

  const selected_project = useSelector(state => state.current_selected_data.selected_project)

  useEffect(() => {
    setProjectDetails({
      ...projectdetails,
      projectName: selected_project?.payload?.name,
      projectDescription: selected_project?.payload?.description
    });
  }, [selected_project])

  useEffect(() => {
    const errors = { ...formErrors };
    if (formErrors?.projectName && projectdetails?.projectName?.trim()) { delete errors?.projectName }
    if (formErrors?.projectDescription && projectdetails?.projectDescription?.trim()) { delete errors?.projectDescription }
    if (JSON.stringify(errors) !== JSON.stringify(formErrors)) { setFormErrors(errors) }
  }, [projectdetails, formErrors]);

  const handleProjectSettings = async () => {
    setLoading(true)
    try {
      const errors = {};
      if (!projectdetails?.projectName?.trim()) { errors.projectName = 'Project name is required' }
      if (!projectdetails?.projectDescription?.trim()) { errors.projectDescription = 'Project description is required' }
      if (Object.keys(errors).length === 0) {
        const EditProjectObject = {
          id: selected_project?.payload?.__auto_id__,
          fields: [
            {
              "path": '$.name',
              "value": projectdetails.projectName
            },
            {
              "path": '$.description',
              "value": projectdetails.projectDescription
            }
          ]
        }
        const response = await ProjectsApi.updateProject(JSON.stringify(EditProjectObject), selected_project?.payload?.__auto_id__, selected_project?.update_key)
        if (response.status === 200) {
          const message = await AlertMessages('update', 'project');
          tostAlert(message, 'success')
        } else {
          const message = await AlertMessages('error', 'project');
          tostAlert(message, 'error')
        }
      }
      else {
        setFormErrors(errors);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  return (
    <Box sx={Styles.mainContainer}>

      {loading && (<LoadBar />)}

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography sx={fontStyles.largeText}>General Settings</Typography>

        <Box display={'flex'} alignItems={'center'}>
          <Button
            sx={{ ...buttonStyles.primaryBtn, width: '100px' }}
            disabled={selected_project === null}
            onClick={() => handleProjectSettings()}
          >
            SAVE
          </Button>
          <Button sx={{ ...buttonStyles.secondaryBtn, width: '100px', marginLeft: '10px' }}>
            CANCEL
          </Button>
        </Box>

      </Box>

      <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
        Configure you app name and icon
      </Typography>

      <Box marginTop={'20px'}>
        <Box>
          <FormControl>
            <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Project Name</Typography>
            <TextField
              id="project-name"
              variant="outlined"
              placeholder='Project Name'
              size='small'
              sx={{ ...searchBarStyles.searchBar, width: '600px' }}
              name="project_name"
              value={projectdetails.projectName}
              onChange={(e) => setProjectDetails({ ...projectdetails, projectName: e.target.value })}
            />
          </FormControl>
          {formErrors.projectName && <Typography sx={fontStyles.formErrorText}>{formErrors.projectName}</Typography>}
        </Box>
        <Box>
          <FormControl sx={{ marginTop: '30px' }}>
            <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Description</Typography>
            <TextField
              id="project-descriptions"
              variant="outlined"
              placeholder='Description'
              size='medium'
              sx={{ ...searchBarStyles.searchBar, width: '600px' }}
              name="project_descriptions"
              value={projectdetails.projectDescription}
              onChange={(e) => setProjectDetails({ ...projectdetails, projectDescription: e.target.value })}
            />
          </FormControl>
          {formErrors.projectDescription && <Typography sx={fontStyles.formErrorText}>{formErrors.projectDescription}</Typography>}
        </Box>
      </Box>

    </Box>
  )
}

export default GenralSettings