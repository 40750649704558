import React, { useState } from 'react'
import { Styles } from './Styles';
import {
    Box, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography
} from '@mui/material';
import { fontStyles } from '../../../styles/fontStyles';
import { searchBarStyles } from '../../../styles/searchBarStyles';
import { mainStyles } from '../../../styles/styles';
import { tableStyles } from '../../../styles/tableStyles';

function ServiceAccountPermission() {
    const [permissions, setPermissions] = useState("all");

    return (
        <Box sx={Styles.mainContainer}>

            <Box>
                <FormControl>
                    <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Service Account ID</Typography>
                    <TextField
                        id="project-descriptions"
                        variant="outlined"
                        size='small'
                        sx={{ ...searchBarStyles.searchBar, width: '600px' }}
                    // value={projectdetails.projectDescription}
                    // onChange={(e) => setProjectDetails({ ...projectdetails, projectDescription: e.target.value })}
                    />
                </FormControl>
            </Box>

            <Box marginTop={"10px"}>
                <FormControl>
                    <FormLabel style={{ color: "black" }}>
                        <Typography sx={{ ...fontStyles.mediumText, marginTop: '10px' }}>
                            Permissions
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={permissions}
                        onChange={(e) => setPermissions(e.target.value)}
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <FormControlLabel value="all" control={<Radio sx={mainStyles.radionBtnColor} />} label="All" />
                        <FormControlLabel value="restricted" control={<Radio sx={mainStyles.radionBtnColor} />} label="Restricted" />
                    </RadioGroup>
                </FormControl>
            </Box>

            <Box marginTop={'30px'} paddingBottom={'20px'}>
                <Paper>
                    < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                        <Table
                        // stickyHeader sx={{ height: documentprocessdata?.test_result?.length <= 0 ? '400px' : `${documentprocessdata?.test_result?.length}px` }}
                        >
                            <TableHead sx={tableStyles.thead}>
                                <TableRow>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '30%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.productionfile_name.en'}
                                        // direction={productionfilesortKey === 'payload.productionfile_name.en' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.productionfile_name.en')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Resource</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '70%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.created_on'}
                                        // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.created_on')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Permisison</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody >

                                <TableRow sx={{ ...tableStyles.trow, borderBottom: 'none' }}>
                                    <TableCell sx={{ ...tableStyles.tdcell, borderBottom: 'none' }}>Resource Group 1</TableCell>
                                    <TableCell sx={{ ...tableStyles.tdcell, borderBottom: 'none' }}>
                                        <Box>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={'none'}
                                                onChange={(e) => setPermissions(e.target.value)}
                                                style={{ display: 'flex', flexDirection: 'row' }}
                                            >
                                                <FormControlLabel value="none" control={<Radio sx={mainStyles.radionBtnColor} />} label="None" />
                                                <FormControlLabel value="read" control={<Radio sx={mainStyles.radionBtnColor} />} label="Read" />
                                                <FormControlLabel value="write" control={<Radio sx={mainStyles.radionBtnColor} />} label="Write" />
                                            </RadioGroup>
                                        </Box>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ ...tableStyles.trow, borderBottom: 'none' }}>
                                    <TableCell sx={{ ...tableStyles.tdcell, borderBottom: 'none' }}>/v1/rg1</TableCell>
                                    <TableCell sx={{ ...tableStyles.tdcell, borderBottom: 'none' }}>
                                        <Box>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={'read'}
                                                onChange={(e) => setPermissions(e.target.value)}
                                                style={{ display: 'flex', flexDirection: 'row' }}
                                            >
                                                <FormControlLabel value="none" control={<Radio sx={mainStyles.radionBtnColor} />} label="None" />
                                                <FormControlLabel value="read" control={<Radio sx={mainStyles.radionBtnColor} />} label="Read" />
                                                <FormControlLabel value="write" control={<Radio sx={mainStyles.radionBtnColor} />} label="Write" />
                                            </RadioGroup>
                                        </Box>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ ...tableStyles.trow, borderBottom: 'none' }}>
                                    <TableCell sx={{ ...tableStyles.tdcell, borderBottom: 'none' }}>/v1/api2</TableCell>
                                    <TableCell sx={{ ...tableStyles.tdcell, borderBottom: 'none' }}>
                                        <Box>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={'write'}
                                                onChange={(e) => setPermissions(e.target.value)}
                                                style={{ display: 'flex', flexDirection: 'row' }}
                                            >
                                                <FormControlLabel value="none" control={<Radio sx={mainStyles.radionBtnColor} />} label="None" />
                                                <FormControlLabel value="read" control={<Radio sx={mainStyles.radionBtnColor} />} label="Read" />
                                                <FormControlLabel value="write" control={<Radio sx={mainStyles.radionBtnColor} />} label="Write" />
                                            </RadioGroup>
                                        </Box>
                                    </TableCell>
                                </TableRow>

                                {/* {documentprocessdata?.test_result?.length === 0 && loading ?
                                    (<TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    (documentprocessdata?.test_result?.length > 0 && PaginatedProcessData?.map((item, index) => (
                                        < TableRow key={index} sx={tableStyles.trow}>
                                            <TableCell style={tableStyles.tdcell}>{item?.test_name}</TableCell>
                                            <TableCell style={tableStyles.tdcell}>
                                                {item?.status === "pass" ?
                                                    <Box>{item?.result_value}&nbsp;{item?.uom}</Box>
                                                    : <Typography sx={{ ...fontStyles.smallText, color: "#C30E2E", fontWeight: 'bold' }}>UNKNOWN</Typography>}
                                            </TableCell>
                                            <TableCell style={tableStyles.tdcell}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                                                    {item?.status === "pass" ?
                                                        (<Box marginRight={'30px'}>
                                                            <ReactSVG src={IconSvg.resultCorrectIcon} />
                                                        </Box>) :
                                                        (<Box marginRight={'30px'} display={'flex'} alignItems={'center'}>
                                                            <ReactSVG src={IconSvg.resultErrorIcon} />
                                                            <Button
                                                                sx={{ ...buttonStyles.tertiaryBtn, width: '80px', marginLeft: '10px' }}
                                                                onClick={() => handleTrainQmsModel(documentprocessdata.test_id)}
                                                            >
                                                                <Typography style={{ ...fontStyles.btnSmallText, fontWeight: 'bold' }}>Train</Typography>
                                                            </Button>
                                                        </Box>)
                                                    }
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )))
                                } */}
                                {/* {!loading && documentprocessdata?.test_result?.length === 0 && (
                                    <TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                )} */}
                            </TableBody>

                        </Table>
                    </TableContainer >

                    {/* {documentprocessdata?.test_result?.length > 0 &&
                        (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box marginLeft={'20px'}>
                                    <Select
                                        value={processDatarowsPerPage}
                                        onChange={handleProcessDataRowsPerPageChange}
                                        sx={{ width: '110px', height: '35px' }}
                                    >
                                        <MenuItem value={'5'}>5 rows</MenuItem>
                                        <MenuItem value={'10'}>10 rows</MenuItem>
                                        <MenuItem value={'15'}>15 rows</MenuItem>
                                        <MenuItem value={'20'}>20 rows</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Pagination
                                count={totalProcessDataPages}
                                page={processDataPage}
                                onChange={handleProcessDataPage}
                                color="standard"
                            />
                        </Box>)
                    } */}

                </Paper >
            </Box >

        </Box >
    )
}

export default ServiceAccountPermission;