import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from "react-svg";
import moment from 'moment';

import {
    Box, Button, CircularProgress, Collapse, Divider, IconButton, LinearProgress, Menu, MenuItem, Pagination, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography
} from '@mui/material'
import { CancelOutlined, CheckCircleOutlineOutlined, CloudUpload, ErrorOutlineOutlined, ExpandMoreSharp, QueryBuilderOutlined } from '@mui/icons-material';

import "./Styles.css"
import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles';
import { tableStyles } from '../../../styles/tableStyles';
import { buttonStyles } from '../../../styles/buttonStyles';

import LoadBar from '../../../utils/LoadBar';
import { IconSvg } from '../../../utils/globalIcons';
import { tostAlert } from '../../../utils/AlertToast';
import { AlertMessages } from '../../../utils/AlertMessages';
import { fetchUserId } from '../../../utils/GetAccountDetails';
import { apiErrorHandler } from '../../../utils/ApiErrorHandler';
import { fileExcistenceCheck } from '../../../utils/FileHandling';
import DocSearchFilesData from '../../../utils/ApiFunctions/DocSearchFilesData';
import getAmeyaSettingsData from '../../../utils/ApiFunctions/AmeyaSettingsData';
import ProjectParentFolders from '../../../utils/ApiFunctions/ProjectParentFolders';

import { setDocSearchFilesState, setProjectSourceFolderState } from "../../../Redux/slice/dataSlice";
import { setProjectSourceFolderAdded, setDocSearchFilesAdded } from "../../../Redux/slice/newDataSlice";

import FilesApi from '../../../Api/Services/AppflyteBackend/FileServiceApi';
import DocumentSearchApi from '../../../Api/Services/AmeyaBackend/DocumentSearchApi';

let resJsonVar = []
let uploadProgress = []

function DocSearchFiles() {

    const dispatch = useDispatch()

    // Files Upload
    const filePermission = "PUBLIC"
    const [uploadJson, setUploadJson] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [patentFolderLoading, setParentFolderLoading] = useState(false)
    const [projectsourceparentFolders, setProjectSourceParentFolders] = useState([])
    const [uploadProgressState, setUploadProgress] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadingSave, SetLoadingSave] = useState(false)

    // Doc Search Files
    const [docSearchFilesInfo, setDocSearchFilesInfo] = useState([]);
    const [docSearchFileLoading, setDocSearchFileLoading] = useState(false);
    const [searchDatarowsPerPage, setSearchDataRowsPerPage] = useState('5');
    const [searchDataPage, setSearchDataPage] = useState(1);
    const [totalSearchDataPages, setTotalSearchDataPages] = useState(1);

    // Realm and llm Configurations
    const [initialLoading, setInitialLoading] = useState(false)
    const [realmSettings, setRealmSettings] = useState([]);
    const [llmSettings, setLLMSettings] = useState({ api_keys: "", llm_model: "" })
    const [values, setValues] = useState({});

    // Realm Filter
    const [isFilterApply, setIsFilterApply] = useState(false);
    const [filterMenu, setFilterMenu] = useState(null);
    const [expandedOptions, setExpandedOptions] = useState({});
    const [inputValues, setInputValues] = useState({});

    // Redux States
    const selected_project = useSelector(state => state.current_selected_data.selected_project)
    const project_source_folders = useSelector(state => state.all_data.project_source_folders)
    const docsearchfiles = useSelector(state => state.all_data.docsearchfiles)
    const projects_source_folder_added = useSelector(state => state.data_added.projects_source_folder_added)
    const doc_search_files_added = useSelector(state => state.data_added.doc_search_files_added)

    useEffect(() => {
        const initializeData = async () => {
            try {
                setInitialLoading(true)
                setDocSearchFileLoading(true)
                // setParentFolderLoading(true)
                const response = await getAmeyaSettingsData(selected_project?.payload?.__auto_id__);
                if (response?.length > 0) {
                    const responseData = response?.[0] ?? [];
                    const realm_settings = responseData?.payload?.settings?.realm_attributes ?? [];
                    const llm_settings = responseData?.payload?.settings?.llm_settings ?? {};
                    if (realm_settings?.length > 0 || llm_settings) {
                        setRealmSettings(realm_settings);
                        setLLMSettings({
                            ...llmSettings,
                            api_keys: typeof llm_settings?.api_keys === "string" ? llm_settings.api_keys : "",
                            llm_model: typeof llm_settings?.llm_model === "string" ? llm_settings.llm_model : ""
                        });
                    }
                }
                setInitialLoading(false)
                getAllDocSearchFiles(false);
                // getAllProjectParentFolders();
            } catch (error) {
                console.error("Error initializing data:", error);
            }
        };

        initializeData();
    }, [selected_project, doc_search_files_added, projects_source_folder_added])

    const getAllProjectParentFolders = async () => {
        let doc_search_source_folders = []
        if (project_source_folders?.length > 0 && projects_source_folder_added === false) {
            doc_search_source_folders = project_source_folders
        } else {
            const response = await ProjectParentFolders()
            dispatch(setProjectSourceFolderState(response))
            dispatch(setProjectSourceFolderAdded(false))
            doc_search_source_folders = response
        }
        const filtered_source_folders = doc_search_source_folders?.filter((item) => item?.id === selected_project?.payload?.__auto_id__)
        setProjectSourceParentFolders(filtered_source_folders)
        setParentFolderLoading(false)
    }

    // Handle Item Filter
    const handleToggle = (item) => {
        setExpandedOptions(prev => ({
            ...prev,
            [item]: !prev[item],
        }));
    };

    const handleInputChange = (item, value) => {
        setInputValues(prev => ({
            ...prev,
            [item]: value,
        }));
    };

    const handleFilterApply = async () => {
        const filteredValues = Object?.fromEntries(Object?.entries(inputValues)?.filter(([key, value]) => value)) || {};
        if (Object.keys(filteredValues).length === 0) {
            tostAlert('Please select at least one filter', 'warning')
            return;
        }
        setIsFilterApply(true)
        setFilterMenu(null)
        getAllDocSearchFiles(true);
    }

    const handleCancelFilter = () => {
        setFilterMenu(null)
        setInputValues({})
        setExpandedOptions({})
        setIsFilterApply(false)
        getAllDocSearchFiles(false);
    }

    // Handling Realm Config
    const handleValueChange = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const getAllDocSearchFiles = async (filter) => {
        try {
            let doc_search_files = []

            const is_pending_files = docsearchfiles?.length > 0 ? docsearchfiles?.filter(item => item?.status === 'Processing') : []

            if (docsearchfiles?.length > 0 && doc_search_files_added === false && !filter && is_pending_files?.length === 0) {
                doc_search_files = docsearchfiles
            } else {
                const filteredValues = Object?.fromEntries(Object?.entries(inputValues)?.filter(([key, value]) => value)) || {};
                const reqObj = {
                    "realm": filteredValues,
                    "project_id": selected_project?.payload?.__auto_id__
                }
                const response = await DocSearchFilesData(JSON.stringify(reqObj))
                if (!filter) {
                    dispatch(setDocSearchFilesState(response))
                    dispatch(setDocSearchFilesAdded(false))
                }
                doc_search_files = response
            }

            const updatedData = doc_search_files?.length > 0 ? doc_search_files?.map(item => {
                const file_format = item?.file_name?.includes('.')
                    ? item.file_name.split('.').pop()
                    : null;

                const fixedDate = item?.uploaded_date?.replace(/:(\d{2}):(\d{2})$/, (match, mins, secs) => {
                    return `:${mins}:${Math.min(Number(secs), 59)}`;
                });
                return { ...item, file_format, fixedDate };
            }) : []

            const sorted_doc_search_files = updatedData?.length > 0
                ? [...updatedData]?.sort((a, b) => {
                    const dateA = Date.parse(a?.fixedDate) ? new Date(a?.fixedDate) : null;
                    const dateB = Date.parse(b?.fixedDate) ? new Date(b?.fixedDate) : null;
                    if (!dateA || !dateB) return !dateA ? 1 : -1;
                    return dateB - dateA;
                })
                : [];
            setDocSearchFilesInfo(sorted_doc_search_files)
        } catch (error) {
            console.log(error)
        } finally {
            setDocSearchFileLoading(false)
        }
    }

    useEffect(() => {
        setTotalSearchDataPages(Math.ceil(docSearchFilesInfo?.length / parseInt(searchDatarowsPerPage)));
    }, [docSearchFilesInfo, searchDatarowsPerPage]);

    let PaginatedDocSearchData = [];
    const handleSearchDataPage = (event, newPage) => { setSearchDataPage(newPage) }

    const handleSearchDataRowsPerPageChange = event => {
        setSearchDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(docSearchFilesInfo?.length / parseInt(newRowsPerPage));
        setSearchDataRowsPerPage(newRowsPerPage);
        setSearchDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalSearchDataPages(newTotalPages);
    };
    if (docSearchFilesInfo?.length > 0) {
        const startIndex = (searchDataPage - 1) * parseInt(searchDatarowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(searchDatarowsPerPage), docSearchFilesInfo?.length);
        PaginatedDocSearchData = docSearchFilesInfo?.slice(startIndex, endIndex);
    }

    // Handle File Upload
    const handleFileDrop = async (event) => {
        event.preventDefault();
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    const handleFileChange = async (event) => {
        event.preventDefault();
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    const handleFileRemove = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    }

    const markIndexFailed = (index) => {
        const tempLoadArr = [...uploadProgress]
        tempLoadArr[index] = 'failed'
        uploadProgress = tempLoadArr
        setUploadProgress([...tempLoadArr])
    }

    const saveInAmeyaBackend = async (fileUrl, fileId, index) => {
        try {
            const userId = await fetchUserId();
            const reqObj = {
                "document_download_url": fileUrl,
                "user_id": userId,
                "realm": Object.keys(values)?.length > 0 ? values : {},
                "document_id": fileId,
                "project_id": selected_project?.payload?.__auto_id__,
                "uploaded_date": moment().format("YYYY-MM-DD HH:MM:SS"),
            }

            const upload_reponse = await DocumentSearchApi.uploadDraftPdfFile(JSON.stringify(reqObj));
            if (upload_reponse.status === 200) {
                const tempLoadArr = [...uploadProgress]
                tempLoadArr[index] = 'processed'
                uploadProgress = tempLoadArr
                setUploadProgress([...tempLoadArr])
                return true
            }
            markIndexFailed(index)
            return null
        } catch (error) {
            markIndexFailed(index)
            return null
        }
    }

    const uploadFile = async (blob, reqBody, index) => {
        try {
            const resData = await FilesApi.getPredignedURL(JSON.stringify(reqBody))
            const urlFields = JSON.parse(resData.data.url_fields)
            let formData = new FormData();

            formData.append('key', urlFields.key);
            formData.append('AWSAccessKeyId', urlFields.AWSAccessKeyId);
            formData.append('x-amz-security-token', urlFields['x-amz-security-token']);
            formData.append('policy', urlFields.policy);
            formData.append('signature', urlFields.signature);
            formData.append('file', blob);

            const resUpload = await FilesApi.uploadFile(resData.data.url, formData)
            if (resUpload.status === 200 || resUpload.status === 204) {
                return resData?.data?.file_id
            }
            return null
        } catch (e) {
            console.log('Err ' + e)
            markIndexFailed(index)
            return null
        }
    }

    const uploadAndAppendForPost = async (file, fileType, thumbnailFileId, index) => {
        try {
            const reqBodyFile = {
                filecontext: 'bucket_dpod_user_file',
                contentType: file?.name?.split('.')[1],
                filetype: '',
                fileName: file?.name
            }
            const uploadedFileId = await uploadFile(file, reqBodyFile, index)
            const uploadedFileUrl = await FilesApi.getUploadedFileUrls(uploadedFileId)
            const tempJsonArr = [...resJsonVar]
            const uploadResObj = {
                access_type: filePermission,
                file_type: fileType,
                folder_id: null,
                file_id: uploadedFileId,
                thumbnail_file_id: thumbnailFileId,
                file_attributes: {
                    file_name: file?.name,
                    file_url: uploadedFileUrl?.data?.[0]?.url,
                    created_on: moment().format("DD-MM-YYYY"),
                    file_extension: file?.name?.split('.')[1]
                }
            }
            tempJsonArr.push(uploadResObj)
            resJsonVar = tempJsonArr
            setUploadJson(tempJsonArr)
            return { "fileId": uploadedFileId, "fileUrl": uploadedFileUrl?.data?.[0]?.url }
        }
        catch (err) {
            console.log("ERROR: ", err);
            markIndexFailed(index)
        }
    }

    const handleDocumentUpload = async (file, index) => {
        try {
            const { fileId, fileUrl } = await uploadAndAppendForPost(file, 'Document', null, index)
            const save_reponse = await saveInAmeyaBackend(fileUrl, fileId, index)
            if (save_reponse) {
                const tempLoadArr = [...uploadProgress]
                tempLoadArr[index] = 'processed'
                uploadProgress = tempLoadArr
                setUploadProgress([...tempLoadArr])
                return
            }
            markIndexFailed(index)
        }
        catch (err) {
            console.log("ERROR: ", err);
            markIndexFailed(index)
        }
    }

    // const handleParentFolder = async (projectParentFolder, current_projectId) => {
    //     if (projectParentFolder?.length > 0 && projectParentFolder?.[0]?.id) {
    //         return projectParentFolder?.[0]?.id
    //     }
    //     try {
    //         const folderObj = {
    //             "folder_name": current_projectId,
    //             "can_be_subscribed": true,
    //             "access_type": "PRIVATE",
    //             "parent_folder_id": null,
    //             "folder_id": current_projectId
    //         }
    //         const folder_response = await FilesApi.createSourceFolders(folderObj)
    //         if (folder_response.status === 200) {
    //             dispatch(setProjectSourceFolderAdded(true))
    //             return folder_response?.data?.folder_id
    //         }
    //         markIndexFailed(0)
    //     } catch (error) {
    //         console.log(error)
    //         markIndexFailed(0)
    //     }
    // }

    const startUploading = async () => {
        try {
            if (selectedFiles?.length === 0) {
                const message = await AlertMessages('warning', 'Files');
                tostAlert(message, 'warning')
                return
            }

            if (!llmSettings?.api_keys?.trim() || !llmSettings?.llm_model?.trim()) {
                tostAlert('LLM Configuration Required', 'warning')
                return
            }

            const missingFields = realmSettings?.filter((key) => !values[key]);
            if (missingFields.length > 0) {
                tostAlert(`Please fill in all fields: ${missingFields.join(', ')}`, 'warning')
                return
            }

            const file_exists = await fileExcistenceCheck(docSearchFilesInfo, selectedFiles)
            if (file_exists) return

            setLoading(true)
            const tempArr = selectedFiles.map(file => 'pending')
            setUploadProgress([...tempArr])
            uploadProgress = tempArr

            selectedFiles.forEach((file, index) => {
                handleDocumentUpload(file, index, null)
                const tempLoadArr = [...uploadProgress]
                tempLoadArr[index] = 'uploading'
                uploadProgress = tempLoadArr
                setUploadProgress([...tempLoadArr])
            })
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        }
    }

    const closeUploadIndicator = () => {
        setLoading(false)
        setUploadProgress([])
        uploadProgress = []
        resJsonVar = []
        setSelectedFiles([])
    }

    const saveUploadChanges = async () => {
        SetLoadingSave(true)
        try {

            const failedFiles = uploadProgressState?.filter(status => status === "failed");
            const successFiles = uploadProgressState?.filter(status => status === "processed" || status === "done");

            if (failedFiles?.length > 0) {
                if (failedFiles?.length > 1) {
                    tostAlert(`${failedFiles?.length} files failed to upload. Please try again.`, 'error');
                    if (successFiles?.length > 0) {
                        dispatch(setDocSearchFilesAdded(true))
                    }
                    return
                }
                tostAlert('File upload failed. Please try again.', 'error');
                return
            }

            const res = await FilesApi.createFile(uploadJson)
            if (res.status === 200 || res.status === 204) {
                dispatch(setDocSearchFilesAdded(true))
                if (uploadProgressState.length > 1) {
                    tostAlert(uploadProgressState.length + " files uploaded", 'success')
                } else {
                    tostAlert("File uploaded", "success")
                }
            }

        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            SetLoadingSave(false)
            setLoading(false)
            uploadProgress = []
            resJsonVar = []
            setUploadProgress([])
            setSelectedFiles([])
        }
    }

    useEffect(() => {
        if (uploadProgressState && uploadProgressState.length && uploadProgressState.length > 0) {
            let i
            let flagToSave = true
            for (i = 0; i < uploadProgressState.length; i++) {
                if (!(uploadProgressState[i] === "processed" || uploadProgressState[i] === "done" || uploadProgressState[i] === "failed")) {
                    flagToSave = false
                    break
                }
            }
            if (flagToSave) {
                saveUploadChanges()
            }
        }
    }, [uploadProgressState])


    const handleSearchFileDelete = async (item) => {

        setLoading(true)
        try {
            const reqObj = {
                "realm": item?.realm ?? {},
                "document_id": item?.document_id,
                "project_id": selected_project?.payload?.__auto_id__,
                "version_id": 0,
                "state": "Draft"
            }
            const res = await DocumentSearchApi.deleteUploadedFiles(JSON.stringify(reqObj))
            if (res.status === 200) {
                dispatch(setDocSearchFilesAdded(true))
                tostAlert('File Deleted Successfully', 'success')
            }
        } catch (error) {
            tostAlert('File Deletion Failed', 'error')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box sx={Styles.mainContainer}>

            <Typography sx={fontStyles.largeText}>Sources - Files</Typography>

            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                Upload documents to index
            </Typography>

            {(loading || docSearchFileLoading) && (<LoadBar />)}
            {
                uploadProgressState && uploadProgressState.length > 0
                    ?
                    <Box sx={Styles.uploadLoadingBox}>
                        <Box sx={Styles.uploadingHeaderBox}>
                            {
                                loadingSave
                                    ?
                                    <Typography sx={Styles.uploadingText}>Saving changes</Typography>
                                    :
                                    <Typography sx={Styles.uploadingText}>Uploading files …</Typography>
                            }
                            {
                                !loadingSave ? <CancelOutlined sx={Styles.closeIcon} onClick={closeUploadIndicator} /> : ''
                            }
                        </Box>
                        {
                            loadingSave ? <LinearProgress width="100%" /> : ''
                        }
                        <Box width="100%">
                            {
                                selectedFiles.map((file, index) =>
                                    <Box key={"upload_progress_ind_key_" + index}>
                                        {
                                            index > 0 ? <Divider width="100%" /> : ''
                                        }
                                        <Box display="flex" height="40px" width="100%" alignItems="center" paddingX="10px">
                                            {
                                                ({
                                                    'uploading': <CircularProgress size="18px" sx={Styles.tickIcon} color="primary" />,
                                                    'processed': <CheckCircleOutlineOutlined sx={Styles.tickIcon} color="primary" />,
                                                    'pending': <QueryBuilderOutlined sx={Styles.tickIcon} color="primary" />,
                                                    'done': <CheckCircleOutlineOutlined sx={Styles.tickIcon} color="primary" />,
                                                    'failed': <ErrorOutlineOutlined sx={Styles.failedIcon} />
                                                }[uploadProgressState[index]])
                                            }
                                            <Typography noWrap sx={Styles.dropZoneFont}>{file?.name}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                    :
                    ''
            }

            <Box marginTop={'20px'} sx={Styles.fileUploadContainer}>
                <Box
                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <input
                        type="file"
                        accept=".pdf,.docx,.doc,.txt,.xls,.xlsx,.csv"
                        onChange={handleFileChange}
                        style={{ display: 'none', cursor: 'pointer' }}
                        id="fileInput"
                        multiple
                    />
                    <label htmlFor="fileInput">
                        {selectedFiles.length === 0 &&
                            <Box>
                                <Typography sx={fontStyles.mediumText}>Drag and drop files or&nbsp;<span style={{ color: '#0B51C5', cursor: 'pointer' }}>click to upload</span> </Typography>
                                <Typography sx={fontStyles.smallText}>Upto 50 MB in size (.pdf, .docx, .doc, .txt, .xls, .xlsx, .csv) </Typography>
                            </Box>
                        }
                        <IconButton component="span">
                            <CloudUpload style={{ color: "black", width: "32px", height: "32px" }} />
                        </IconButton>
                    </label>
                </Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    {
                        selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                            < Box key={index} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'#FFFFFF'} borderRadius={'20px'}>
                                <Typography sx={fontStyles.smallText}>{file.name}</Typography>
                                <Box marginLeft={'10px'} onClick={() => handleFileRemove(index)}>
                                    <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                </Box>
                            </Box>
                        ))
                    }
                </Box>
            </Box >

            {(!initialLoading && realmSettings?.length > 0) &&
                (<Box marginTop={'20px'}>
                    <Typography sx={fontStyles.largeText}>Realm Settings</Typography>
                    <Box marginTop={'10px'}>
                        {realmSettings?.length > 0 ?
                            (<Box display={'flex'} alignItems={'center'}>
                                {realmSettings.map((key, index) => (
                                    <Box key={index} marginRight={'15px'}>
                                        <Typography sx={{ width: '150px', marginBottom: '10px' }}>{key}</Typography>
                                        <TextField
                                            value={values?.[key] || ''}
                                            onChange={handleValueChange}
                                            placeholder={`Enter ${key}`}
                                            size="small"
                                            sx={{ width: '200px' }}
                                            name={key}
                                        />
                                    </Box>
                                ))}
                            </Box>)
                            :
                            (<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Typography>No Records To Display</Typography>
                            </Box>)}
                    </Box>
                </Box>)
            }

            {initialLoading && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'20px'}>
                    <CircularProgress />
                </Box>
            )}

            <Box marginTop={'30px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Button
                    sx={{ ...buttonStyles.primaryBtn, width: '180px', height: '40px' }}
                    disabled={docSearchFileLoading}
                    onClick={() => startUploading()}
                >
                    <Typography style={fontStyles.btnText}>Upload and Train</Typography>
                </Button>
            </Box>

            <Box display={'flex'} justifyContent={'end'} alignItems={'center'} marginTop={'20px'} color={'#0B51C5'}>
                <Box display={'flex'} alignItems={'center'} alignSelf={'center'} cursor={'pointer'}
                    onClick={(event) => setFilterMenu(event.currentTarget)}
                >
                    <ReactSVG src={IconSvg.filterOpenIcon} className={isFilterApply ? 'filter_icon_active' : 'filter_icon'} />
                    <Typography sx={{ ...fontStyles.smallText, marginLeft: '10px' }}>Filter</Typography>
                </Box>

                <Box>
                    <Menu
                        anchorEl={filterMenu}
                        open={filterMenu}
                        onClose={() => setFilterMenu(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        sx={{ borderRadius: '15px' }}
                    >
                        {realmSettings?.length > 0 ?
                            (<MenuItem sx={{ '&:hover': { backgroundColor: 'transparent' }, borderRadius: '15px' }} >
                                <Box display={"flex"} flexDirection={"column"} maxHeight={"300px"} overflowY={"auto"} padding={"20px"} width={"300px"} paddingBottom={"70px"}>
                                    <Box flexGrow={1}></Box>
                                    {realmSettings?.map((item, index) =>
                                    (<Box key={index} display={"flex"} flexDirection={"column"} marginBottom={"15px"} padding={"5px"} borderRadius={"5px"} border={"1px solid #D9D9D9"}>
                                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}
                                            onClick={() => handleToggle(item)}>
                                            <Typography noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item}</Typography>
                                            <Box><ExpandMoreSharp /></Box>
                                        </Box>

                                        <Collapse in={expandedOptions[item]} timeout="auto" unmountOnExit>
                                            <Box marginTop={1}>
                                                <TextField
                                                    placeholder={`Enter value for ${item}`}
                                                    value={inputValues[item] || ''}
                                                    onChange={(e) => handleInputChange(item, e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Box>
                                        </Collapse>
                                    </Box>))}
                                </Box>
                            </MenuItem>)
                            :
                            (<MenuItem>No Records To Display</MenuItem>)
                        }
                        <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

                        <Box display={"flex"} justifyContent={"end"} alignItems={"center"} padding={"5px 5px"}>
                            <Button sx={{ ...buttonStyles.secondaryBtn, width: '100px' }}
                                onClick={() => handleCancelFilter()}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{ ...buttonStyles.primaryBtn, width: '100px', marginLeft: '10px' }}
                                onClick={() => handleFilterApply()}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Menu>
                </Box>
            </Box>

            <Box marginTop={'10px'} paddingBottom={'20px'}>
                <Paper>
                    < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                        <Table
                            stickyHeader sx={{ height: PaginatedDocSearchData.length <= 0 ? '400px' : `${PaginatedDocSearchData.length}px` }}
                        >
                            <TableHead sx={tableStyles.thead}>
                                <TableRow>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '35%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.productionfile_name.en'}
                                        // direction={productionfilesortKey === 'payload.productionfile_name.en' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.productionfile_name.en')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Document</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '20%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.created_on'}
                                        // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.created_on')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Indexing Status</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '15%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.created_on'}
                                        // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.created_on')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Created On</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '20%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.created_on'}
                                        // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.created_on')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Realms</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '10%' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {PaginatedDocSearchData?.length === 0 && docSearchFileLoading ?
                                    (<TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    (PaginatedDocSearchData?.length > 0 && PaginatedDocSearchData.map((item, index) => (
                                        < TableRow key={index} sx={tableStyles.trow}>
                                            <TableCell style={tableStyles.tdcell}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                                                        <ReactSVG src={IconSvg.filesIcon} />
                                                        {/* {item?.file_format === "pdf" && <ReactSVG src={IconSvg.pdfIcon} />}
                                                        {item?.file_format === "docx" && <ReactSVG src={IconSvg.docIcon} />}
                                                        {item?.file_format === "txt" && <ReactSVG src={IconSvg.txtIcon} />}
                                                        {item?.file_format === "pptx" && <ReactSVG src={IconSvg.pptIcon} />}
                                                        {item?.file_format === "xlsx" && <ReactSVG src={IconSvg.xlsxIcon} />}
                                                        {item?.file_format === "csv" && <ReactSVG src={IconSvg.csvIcon} />}
                                                        {item?.file_format !== "pdf" && item?.file_format !== "txt" && item?.file_format !== "docx" &&
                                                            item?.file_format !== "pptx" && <ReactSVG src={IconSvg.filesIcon} />} */}
                                                    </span>
                                                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        <Tooltip placement='right-start'
                                                            title={
                                                                <div style={{ padding: "10px" }}>
                                                                    <p>{item?.file_name}</p>
                                                                </div>
                                                            }
                                                            arrow PopperProps={{
                                                                style: { width: '250px' }
                                                            }}>
                                                            {item?.file_name?.length > 30 ? `${item?.file_name?.substring(0, 50)}...` : item?.file_name}
                                                        </Tooltip>
                                                    </span>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={tableStyles.tdcell}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    {
                                                        item?.status === "Processing" && (
                                                            <span style={{ marginRight: '10px' }}>
                                                                <ReactSVG src={IconSvg.trainedIcon} className='upload_proccess_icon' />
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        item?.status === "Success" && (
                                                            <span style={{ marginRight: '10px' }}>
                                                                <ReactSVG src={IconSvg.trainedIcon} className='upload_success_icon' />
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        item?.status === "Failed" && (
                                                            <span style={{ marginRight: '10px' }}>
                                                                <ReactSVG src={IconSvg.trainedIcon} className='upload_failed_icon' />
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        item?.status === "Processing" ?
                                                            (<span>processing</span>) :
                                                            (<span>{item?.status}</span>)
                                                    }
                                                </Box>
                                            </TableCell>
                                            <TableCell style={tableStyles.tdcell}>{item?.uploaded_date?.substring(0, 10)}</TableCell>
                                            <TableCell style={tableStyles.tdcell}>
                                                {item?.realm && (
                                                    <Tooltip
                                                        title={
                                                            <div style={{ padding: '8px', fontSize: '16px' }}>
                                                                {Object.entries(item.realm).map(([key, value]) => (
                                                                    <div key={key}>
                                                                        {`${key}: ${value}`}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                    >
                                                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            {Object.entries(item.realm)
                                                                .slice(0, 1)
                                                                .map(([key, value]) => `${key}: ${value}`)
                                                                .join(', ')}
                                                            {Object.keys(item?.realm).length > 1 ? ', ...' : ''}
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={tableStyles.tdcell}
                                                onClick={() => handleSearchFileDelete(item)}
                                            >
                                                <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                                {!docSearchFileLoading && PaginatedDocSearchData?.length === 0 && (
                                    <TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer >
                    {docSearchFilesInfo?.length > 0 &&
                        (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box marginLeft={'20px'}>
                                    <Select
                                        value={searchDatarowsPerPage}
                                        onChange={handleSearchDataRowsPerPageChange}
                                        sx={{ width: '110px', height: '35px' }}
                                    >
                                        <MenuItem value={'5'}>5 rows</MenuItem>
                                        <MenuItem value={'10'}>10 rows</MenuItem>
                                        <MenuItem value={'15'}>15 rows</MenuItem>
                                        <MenuItem value={'20'}>20 rows</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Pagination
                                count={totalSearchDataPages}
                                page={searchDataPage}
                                onChange={handleSearchDataPage}
                                color="standard"
                            />
                        </Box>)
                    }
                </Paper>
            </Box>

        </Box >
    )
}

export default DocSearchFiles;