import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

// import DataSourceDatabse from './Database/DataSourceDatabse';
// import DataSourceLinks from './Links/DataSourceLinks';
// import DataSourceSightMaps from './SightMap/DataSourceSightMaps';
import { Styles } from './Styles';
import SubSidebar from './SubSidebar/SubSidebar';

// Document Handling
import DocSearchFiles from './DocumentSearch/DocSearchFiles';
import AuditToolFiles from './AuditTool/AuditToolFiles';
import LLMDocSearchFiles from './LLMDocSearch/LLMDocSearchFiles';

function DataSourceHome() {

    const navigate = useNavigate();
    const location = useLocation();
    const selected_project = useSelector(state => state.current_selected_data.selected_project)

    const handleMenuChange = (engine, path) => {
        if (engine && path) {
            navigate(`/datasource/${engine?.toLowerCase()?.replace(/_/g, '-')}/${path?.toLowerCase()?.replace(/_/g, '-')}`);
        }
    };

    useEffect(() => {
        const selected_engine_name = selected_project?.payload?.configuration?.engine_name
        if (location.pathname === '/datasource') {
            if (selected_engine_name === "qms_audit_tool") {
                navigate('/datasource/qms-audit-tool/files');
            }
            if (selected_engine_name === "doc_search") {
                navigate('/datasource/doc-search/files');
            }
            if (selected_engine_name === "llm_doc_search") {
                navigate('/datasource/llm-doc-search/files');
            }
        }
    }, [location.pathname, navigate, selected_project]);


    return (
        <Box sx={Styles.mainContainer}>

            <Box sx={Styles.sidebar}>
                <SubSidebar handleMenuChange={handleMenuChange} />
            </Box>

            <main style={Styles.componentContainer}>
                <Routes>
                    <Route path="qms-audit-tool/files" element={<AuditToolFiles />} />
                    <Route path="doc-search/files" element={<DocSearchFiles />} />
                    <Route path="llm-doc-search/files" element={<LLMDocSearchFiles />} />
                </Routes>
            </main>

        </Box>
    )
}

export default DataSourceHome;